const DropdownArrow = ({ color }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      width="8"
      height="19"
      viewBox="0 0 531.74 460.5"
      overflow="visible"
      enableBackground="new 0 0 531.74 460.5"
      xmlSpace="preserve"
    >
      <polygon
        stroke={color ?? "#FFFFFF"}
        fill={color ?? "#FFFFFF"}
        points="530.874,0.5 265.87,459.5 0.866,0.5 "
      />
    </svg>
  )
}

export default DropdownArrow
