//React imports
import { useEffect, useState } from "react";
//Local imports
import ArrowIcon from "../arrow/arrow.component";
//Styles
import "./pagination.component.scss";

//Definitions
/**Pagination props */
interface PaginationProps {
  /**Amount of pages in the paginaiton */
  pages: number;

  /**Handler for click on pages */
  onClickHandler: (page: number) => void;

  /**Color for pagination */
  color?: string;

  /**Default page for start */
  defaultPage?: number;

  /**Flag for hide arrows */
  arrowless?: boolean;
}

/**Pagination component */
const Pagination = ({
  pages,
  onClickHandler,
  color,
  defaultPage,
  arrowless,
}: PaginationProps) => {
  //State
  //Current page
  const [currentPage, setCurrentPage] = useState(
    defaultPage ? (defaultPage > 0 ? defaultPage : 0) : 0
  );

  //On default page changes
  useEffect(() => {
    setCurrentPage(defaultPage ? (defaultPage > 0 ? defaultPage : 0) : 0);
  }, [defaultPage]);

  return (
    <div className="pagination">
      {!arrowless ? (
        <div
          className="pagination__arrow"
          style={{
            opacity: currentPage === 0 ? "0.7" : undefined,
            transform: "rotate(180deg)",
            cursor: currentPage === 0 ? "not-allowed" : "pointer",
            scale: currentPage === 0 ? "1.1" : undefined,
          }}
        >
          <ArrowIcon
            color={color}
            onClick={
              currentPage === 0
                ? undefined
                : () => {
                    setCurrentPage(currentPage - 1);
                    onClickHandler(currentPage - 1);
                  }
            }
          />
        </div>
      ) : (
        <></>
      )}
      <div className="pagination__pages">
        {Array(pages)
          .fill(null)
          .map((_, i) => (
            <div
              key={`${pages}-pagination-default-${
                defaultPage ? (defaultPage > 0 ? defaultPage : 0) : 0
              }-page-${i}`}
              className={`pagination__pages__page${
                currentPage === i ? " selected" : ""
              }`}
              style={{
                borderColor: color ?? "#0085CA",
              }}
              onClick={() => {
                setCurrentPage(i);
                onClickHandler(i);
              }}
            >
              <div
                style={{
                  background: color ?? "#0085CA",
                }}
                className="pagination__pages__page__dot"
              />
            </div>
          ))}
      </div>
      {!arrowless ? (
        <div
          className="pagination__arrow"
          style={{
            opacity: currentPage === pages - 1 ? "0.7" : undefined,
            cursor: currentPage === pages - 1 ? "not-allowed" : "pointer",
            transform: currentPage === pages - 1 ? "scale(1)" : undefined,
          }}
        >
          <ArrowIcon
            color={color}
            onClick={
              currentPage === pages - 1
                ? undefined
                : () => {
                    setCurrentPage(currentPage + 1);
                    onClickHandler(currentPage + 1);
                  }
            }
          />
        </div>
      ) : undefined}
    </div>
  );
};

export default Pagination;
