//React imports
import { useContext, useEffect, useState } from "react";
//Third parties imports
import { Link } from "react-router-dom";
//Local imports
import ArrowIcon from "../../components/arrow/arrow.component";
import Banner from "../../components/banner/banner.component";
import MediaCarousel from "../../components/media-carousel/media-carousel.component";
import OverlayMedia from "../../components/media/overlay-media.component";
import SEOHead from "../../components/seo-head/seo-head.component";
import {
  animeJSBaseParams,
} from "../../config/animejs.config";
import { AnalyticsContext } from "../../context/analytics.context";
import { PreloadContext } from "../../context/preload.context";
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/animations.hook";
import usePressReleases from "../../hooks/press-releases.hook";
import { useProjectsHook } from "../../hooks/projects.hook";
import { HomeDoc } from "../../interfaces/home";
import { PressRelease } from "../../interfaces/press-releases";
import { Project } from "../../interfaces/projects";
import { monthsArray } from "../../utils/date.utils";
import { slugifyString } from "../../utils/strings.utils";
//Styles
import "./home.view.scss";

/**Home view component */
const HomeView = () => {
  //Contexts
  //Preload context
  const { getViewData } = useContext(PreloadContext);

  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext);

  //Hooks
  //Animation hook
  const { execute, init } = useViewAnimations();

  /**Projects hook */
  const { get: getProjects } = useProjectsHook(2);

  /**Press releases hook */
  const { get: getReleases } = usePressReleases(3);

  //State
  //View contents
  const [content, setContent] = useState<HomeDoc | undefined>(
    getViewData("Home") as HomeDoc
  );

  //Projects array
  const [projects, setProjects] = useState<Project[]>(getProjects());

  //Press releases array
  const [pressReleases, setPressReleases] = useState<PressRelease[]>(
    getReleases()
  );

  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false);

  //Emit page view event
  useEffect(() => {
    if (content && !pageViewSent) {
      //Home data fetched and page view not sent yet
      emitPageView(content.seo?.title || "Nexwell Power | Home");

      //Update flag
      setPageViewSent(true);
    }
    // eslint-disable-next-line
  }, [content]);

  //On press releases changes
  useEffect(() => {
    if (getReleases()) setPressReleases(getReleases());
  }, [getReleases()]);

  //On projects change
  useEffect(() => {
    if (getProjects()) setProjects(getProjects());
  }, [getProjects()]);

  //On contents change
  useEffect(() => {
    //Update content in state
    setContent(getViewData("Home") as HomeDoc);

    // eslint-disable-next-line
  }, [getViewData("Home")]);

  //On first load
  useEffect(() => {
    //On browser
    if (typeof window !== "undefined")
      window.scrollTo({ top: 0, behavior: "smooth" }); //Scroll to top

    /**Animations targets */
    const targets: AnimationTarget[] = [
      {
        //Section 2 title
        identifier: ".home__section2__title",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".home__section2__title",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Section 2 items
        identifier: ".home__section2__items__item",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".home__section2__items__item",
          autoplay: false,
          translateX: ["-100vw", 0],
          delay: (_el, i, _l) => 100 * i,
        },
      },
      {
        //Section 2 separators
        identifier: ".home__section2__items__separator",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".home__section2__items__separator",
          autoplay: false,
          translateX: ["-100vw", 0],
          opacity: [0, 1],
          delay: (_el, i, _l) => 100 * i,
          duration: 750,
        },
      },
      {
        //Projects title
        identifier: ".home__projects__title",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".home__projects__title",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Projects link
        identifier: ".home__projects__link",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".home__projects__link",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Projects items
        identifier: ".home__projects__items",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".home__projects__items",
          autoplay: false,
          scale: [0.75, 1],
          opacity: [0, 1],
        },
      },
      {
        //News title
        identifier: ".home__news__title",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".home__news__title",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //News link
        identifier: ".home__news__link",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".home__news__link",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Press releases
        identifier: ".home__news__items",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".home__news__items",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
    ];

    //Animations initialization
    init(targets);
  }, []);

  // /**Firestore ref */
  // const firestore = useFirestore();

  // /**Migrate everything */
  // const migrate = async () => {
  //   /**Contents to migrate */
  //   const contents: DocId[] = [
  //     "ESGGovernance",
  //     "ESGV2"
  //   ];

  //   //Iterate contents to migrate
  //   for (const contentId of contents) {
  //     /**Content data from development */
  //     const contentData = (
  //       await firestore
  //         .collection("NexwellPower2021")
  //         .doc("development")
  //         .collection("Content")
  //         .doc(contentId)
  //         .get()
  //     ).data();

  //     //Set data on production if content data existed
  //     if (contentData) {
  //       await firestore
  //         .collection("NexwellPower2021")
  //         .doc("production")
  //         .collection("Content")
  //         .doc(contentId)
  //         .set(contentData, {
  //           merge: true,
  //         });
  //     }
  //   }

  //   // Migrate press releases
  //   // const releases: PressRelease[] = (
  //   //   await firestore
  //   //     .collection("NexwellPower2021")
  //   //     .doc("development")
  //   //     .collection("News")
  //   //     .get()
  //   // ).docs.map((doc) => ({
  //   //   id: doc.id,
  //   //   ...(doc.data() as PressRelease),
  //   // }));

  //   // Iterate to migrate
  //   // for (const release of releases) {
  //   //   //Update reference in production
  //   //   await firestore
  //   //     .collection("NexwellPower2021")
  //   //     .doc("production")
  //   //     .collection("News")
  //   //     .doc(release.id!)
  //   //     .set(release, {
  //   //       merge: true,
  //   //     });
  //   // }

  //   // // Migrate projects
  //   // const projects: Project[] = (
  //   //   await firestore
  //   //     .collection("NexwellPower2021")
  //   //     .doc("development")
  //   //     .collection("Projects")
  //   //     .get()
  //   // ).docs.map((doc) => ({
  //   //   id: doc.id,
  //   //   ...(doc.data() as Project),
  //   // }));

  //   // // Iterate to migrate
  //   // for (const project of projects) {
  //   //   //Update reference in production
  //   //   await firestore
  //   //     .collection("NexwellPower2021")
  //   //     .doc("production")
  //   //     .collection("Projects")
  //   //     .doc(project.id!)
  //   //     .set(project, {
  //   //       merge: true,
  //   //     });
  //   // }

  //   // // Migrate offered positions
  //   // const positions: OfferPosition[] = (
  //   //   await firestore
  //   //     .collection("NexwellPower2021")
  //   //     .doc("development")
  //   //     .collection("Positions")
  //   //     .get()
  //   // ).docs.map((doc) => ({
  //   //   id: doc.id,
  //   //   ...(doc.data() as OfferPosition),
  //   // }));

  //   // // Iterate to migrate
  //   // for (const position of positions) {
  //   //   //Update reference in production
  //   //   await firestore
  //   //     .collection("NexwellPower2021")
  //   //     .doc("production")
  //   //     .collection("Positions")
  //   //     .doc(position.id!)
  //   //     .set(position, {
  //   //       merge: true,
  //   //     });
  //   // }
  // };

  //Component
  return (
    <>
      {content?.seo ? <SEOHead {...content.seo} /> : <></>}
      <Banner
        specificClassNames="home__banner"
        textureUrl={content?.banner.textureUrl}
        background={{
          color: content?.banner.backgroundColor || "#FFFFFF",
        }}
        separator={{
          color: content?.banner.separatorColor,
          className: "home__banner__separator",
        }}
        ringImageUrl={content?.banner.ringsUrl || ""}
        title={
          <div
            className="home__banner__title"
            dangerouslySetInnerHTML={{
              __html: content?.banner.title || "",
            }}
          />
        }
        subtitle={
          <>
            <div
              className="home__banner__subtitle"
              dangerouslySetInnerHTML={{
                __html: content?.banner.subtitle || "",
              }}
            />
            <Link
              to={content?.banner.redirection.route || ""}
              className="home__banner__link redirection-link"
              id="home__banner__link"
              style={{
                color: content?.banner.redirection.color,
              }}
              onMouseEnter={() => {
                execute({
                  ...animeJSBaseParams,
                  targets: "#home__banner__link",
                  translateX: [0, "5%"],
                  scale: [1, 1.1],
                  opacity: [1, 0.7],
                });
              }}
              onMouseLeave={() => {
                execute({
                  ...animeJSBaseParams,
                  targets: "#home__banner__link",
                  translateX: ["5%", 0],
                  scale: [1.1, 1],
                  opacity: [0.7, 1],
                });
              }}
            >
              {content?.banner.redirection.name}
              <ArrowIcon color={content?.banner.redirection.color} />
            </Link>
          </>
        }
      />
      {/* <button onClick={migrate}>MIGRATE DATA</button> */}
      <section
        className="home__section2"
        style={{
          borderWidth: "0 0 0 1px",
          borderColor: content?.section2.separatorsColor,
          borderStyle: "solid",
        }}
      >
        <div
          className="home__section2__title"
          dangerouslySetInnerHTML={{
            __html: content?.section2.title || "",
          }}
        />
        <div className="home__section2__items">
          {(content?.section2.items ?? []).map((item, i) => (
            <>
              {i > 0 ? (
                <hr
                  key={`section2-items-item-${i}-separator`}
                  className="home__section2__items__separator"
                  style={{
                    backgroundColor: content?.section2.separatorsColor,
                  }}
                />
              ) : (
                <></>
              )}
              <div
                key={`section2-items-item-${i}`}
                className="home__section2__items__item"
                dangerouslySetInnerHTML={{
                  __html: item,
                }}
                id={`section2-item-${i}`}
                onMouseEnter={() => {
                  execute({
                    ...animeJSBaseParams,
                    targets: `#section2-item-${i}`,
                    opacity: [1, 0.7],
                    scale: [1, 1.05],
                  });
                }}
                onMouseLeave={() => {
                  execute({
                    ...animeJSBaseParams,
                    targets: `#section2-item-${i}`,
                    opacity: [0.7, 1],
                    scale: [1.05, 1],
                  });
                }}
              />
            </>
          ))}
        </div>
        <div className="home__projects">
          <div
            className="home__projects__title"
            dangerouslySetInnerHTML={{
              __html: content?.projects.title || "",
            }}
          />
          <Link
            className="home__projects__link redirection-link"
            to={content?.projects.redirection.route || ""}
            style={{
              color: content?.projects.redirection.color,
            }}
            id="home__projects__link"
            onMouseEnter={() => {
              execute({
                ...animeJSBaseParams,
                targets: "#home__projects__link",
                translateX: [0, "5%"],
                scale: [1, 1.1],
                opacity: [1, 0.7],
              });
            }}
            onMouseLeave={() => {
              execute({
                ...animeJSBaseParams,
                targets: "#home__projects__link",
                translateX: ["5%", 0],
                scale: [1.1, 1],
                opacity: [0.7, 1],
              });
            }}
          >
            {content?.projects.redirection.name}
            <ArrowIcon color={content?.projects.redirection.color} />
          </Link>
          <div className="home__projects__items">
            {projects.map((project) => (
              <div
                className="home__projects__items__item"
                key={`project-${project.id}`}
                id={`project-${project.id}`}
                onMouseEnter={() => {
                  execute({
                    ...animeJSBaseParams,
                    targets: `#project-${project.id}`,
                    scale: [1, 1.05],
                  });
                }}
                onMouseLeave={() => {
                  execute({
                    ...animeJSBaseParams,
                    targets: `#project-${project.id}`,
                    scale: [1.05, 1],
                  });
                }}
              >
                <MediaCarousel
                  mediaItems={project.images ?? []}
                  containerClassName="home__projects__items__item__image-container"
                  mediaClassName="home__projects__items__item__image"
                />
                <h3 className="home__projects__items__item__name">
                  {project.name}
                </h3>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="home__news">
        <div className="home__news__top">
          <OverlayMedia
            media={{
              type: "image",
              url: "",
              ...content?.news.backgroundMedia,
            }}
            containerClassName="home__news__top__bg"
            mediaClassName="home__news__top__media"
          />
          <div
            className="home__news__top__title"
            dangerouslySetInnerHTML={{
              __html: content?.news.title ?? "",
            }}
          />
          <Link
            className="home__news__top__link redirection-link"
            to={content?.news.redirection.route ?? ""}
            style={{
              color: content?.news.redirection.color,
            }}
            id="home__news__link"
            onMouseEnter={() => {
              execute({
                ...animeJSBaseParams,
                targets: "#home__news__link",
                translateX: [0, "5%"],
                scale: [1, 1.1],
                opacity: [1, 0.7],
              });
            }}
            onMouseLeave={() => {
              execute({
                ...animeJSBaseParams,
                targets: "#home__news__link",
                translateX: ["5%", 0],
                scale: [1.1, 1],
                opacity: [0.7, 1],
              });
            }}
          >
            {content?.news.redirection.name}
            <ArrowIcon color={content?.news.redirection.color} />
          </Link>
        </div>
      </section>
      <section className="home__news__items">
        {pressReleases.map((release, i) => (
          <>
            {i > 0 ? (
              <hr
                className="home__news__items__separator"
                key={`press-releases__separator-${release.id}`}
                style={{
                  backgroundColor: content?.news.separatorColor,
                }}
              />
            ) : (
              <></>
            )}
            <div
              className="home__news__items__item"
              id={`press-release-${release.id}`}
              onMouseEnter={() => {
                execute({
                  ...animeJSBaseParams,
                  targets: `#press-release-${release.id}`,
                  scale: [1, 1.1],
                  opacity: [1, 0.7],
                });
              }}
              onMouseLeave={() => {
                execute({
                  ...animeJSBaseParams,
                  targets: `#press-release-${release.id}`,
                  scale: [1.1, 1],
                  opacity: [0.7, 1],
                });
              }}
            >
              <Link
                style={{
                  textDecoration: "none",
                }}
                key={`press-releases__release-${release.id}`}
                to={`/press-releases?title=${slugifyString(release.title)}`}
              >
                <div className="home__news__items__item__date">
                  {monthsArray[release.releaseDate.toDate().getMonth()]},{" "}
                  {release.releaseDate.toDate().getFullYear()}
                </div>
                <div className="home__news__items__item__title">
                  {release.title}
                </div>
                <p className="home__news__items__item__link redirection-link">
                  Read more
                </p>
              </Link>
            </div>
          </>
        ))}
      </section>
    </>
  );
};

export default HomeView;
