import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowIcon from "../../components/arrow/arrow.component";
import Banner from "../../components/banner/banner.component";
import { PreloadContext } from "../../context/preload.context";
import { getBackgroundWithOverlay } from "../../utils/overlay.utils";
import { AboutUsDoc } from "./../../interfaces/about-us";

import "./about-us.view.scss";
import useViewAnimations, {
  AnimationTarget,
} from "./../../hooks/animations.hook";
import { animeJSBaseParams } from "../../config/animejs.config";
import SEOHead from "../../components/seo-head/seo-head.component";
import { AnalyticsContext } from "../../context/analytics.context";
import anime from "animejs";
import OverlayMedia from "../../components/media/overlay-media.component";

type ProcessGridItemSpans = "1 / 2" | "2 / 3" | "1 / 3";
type ProcessGridItemPositions = "left" | "right" | "center";

const outerDegs = [45, 135, 225, 315];
const innerDegs = [0, 40, 80, 120, 160, 200, 240, 280, 320];

const outerDegsMobile = [0, 90, 180, 270];

// const processGridItemSpans: ProcessGridItemSpans[] = [
//   "1 / 2",
//   "2 / 3",
//   "1 / 3",
//   "1 / 2",
//   "2 / 3",
//   "1 / 3",
//   "1 / 3",
// ];

const processGridItemsPositions: ProcessGridItemPositions[] = [
  "left",
  "right",
  "center",
  "left",
  "right",
  "center",
  "center",
];

const processGridItemSpanByPosition: {
  [position in ProcessGridItemPositions]: ProcessGridItemSpans;
} = {
  left: "1 / 2",
  right: "2 / 3",
  center: "1 / 3",
};

const processGridItemMarginByPosition: {
  [position in ProcessGridItemPositions]: string;
} = {
  left: "20px 0 0 20px",
  right: "20px 0 0 -20px",
  center: "36px auto 0",
};

const shouldFormat = [true, true, true, true, true, false, true];

const formatCycleItemText = (text: string, index = -1): JSX.Element => {
  const trimmedText = text.trim();

  const textSplit = trimmedText.split(" ");

  if (index === 0) {
    console.log("here")
    return (
      <span>
        {textSplit[0]}
        <br />
        {textSplit.slice(1).map((word) => <>{word} </>)}
      </span>
    );
  }

  if (textSplit.length === 2) {
    return (
      <span>
        {textSplit[0]}
        <br />
        {textSplit[1]}
      </span>
    );
  }

  return <>{text}</>;
};

const AboutUs = () => {
  const targets: AnimationTarget[] = [
    {
      identifier: ".our-business-model-section__title",
      viewOffset: [0, -200],
      animationParams: {
        targets: ".our-business-model-section__title",
        translateY: [-200, 0],
        opacity: [0, 1],
        delay: 200,
        autoplay: false,
        ...animeJSBaseParams,
      },
    },
    {
      identifier: ".cycle-container.desktop",
      viewOffset: [0, -200],
      animationParams: {
        targets:
          ".cycle-container.desktop .cycle.outer img, .cycle-container.desktop .cycle.inner img",
        scale: [0, 1],
        opacity: [0, 1],
        rotate: "1turn",
        delay: function (el, i, l) {
          return i * 300 + 100;
        },
        autoplay: false,
        ...animeJSBaseParams,
        complete: (instance) => {
          anime({
            targets: ".cycle-container.desktop .cycle.outer img",
            rotate: [0, 1],
            loop: true,
            autoplay: true,
            ...animeJSBaseParams,
            easing: "linear",
            duration: 50000,
          });
          anime({
            targets: ".cycle-container.desktop .cycle.inner img",
            rotate: [0, 1],
            loop: true,
            autoplay: true,
            ...animeJSBaseParams,
            easing: "linear",
            duration: 32000,
          });
        },
      },
    },
    {
      identifier: ".cycle-container.desktop",
      viewOffset: [0, -200],
      animationParams: {
        targets: ".cycle-container.desktop .cycle.outer .dot .dot-text-payload",
        scale: [0, 1],
        opacity: [0, 1],
        delay: function (el, i, l) {
          return i * 100 + 400;
        },
        autoplay: false,
        ...animeJSBaseParams,
      },
    },
    {
      identifier: ".cycle-container.desktop",
      viewOffset: [0, -200],
      animationParams: {
        targets: ".cycle-container.desktop .cycle.inner .dot .dot-text-payload",
        scale: [0, 1],
        opacity: [0, 1],
        delay: function (el, i, l) {
          return i * 100 + 700;
        },
        autoplay: false,
        ...animeJSBaseParams,
      },
    },
    {
      identifier: ".cycle-container.mobile",
      viewOffset: [0, -200],
      animationParams: {
        targets: ".cycle-container.mobile .cycle.outer img",
        scale: [0, 1],
        opacity: [0, 1],
        rotate: ["367deg", "7deg"],
        delay: function (el, i, l) {
          return i * 300 + 100;
        },
        autoplay: false,
        ...animeJSBaseParams,
      },
    },
    {
      identifier: ".cycle-container.mobile",
      viewOffset: [0, -200],
      animationParams: {
        targets: ".cycle-container.mobile .cycle.outer .dot",
        scale: [0, 1],
        opacity: [0, 1],
        delay: function (el, i, l) {
          return i * 100 + 700;
        },
        autoplay: false,
        ...animeJSBaseParams,
      },
    },
    {
      identifier: ".process-container .process",
      viewOffset: [0, -200],
      animationParams: {
        targets: ".process-container",
        scale: [0, 1],
        opacity: [0, 1],
        autoplay: false,
        ...animeJSBaseParams,
      },
    },
    {
      identifier: ".process-container .process",
      viewOffset: [0, -200],
      animationParams: {
        targets: ".process-container .process .process__item",
        scale: [0, 1],
        opacity: [0, 1],
        delay: function (el, i, l) {
          return i * 100 + 400;
        },
        autoplay: false,
        ...animeJSBaseParams,
      },
    },
    {
      identifier: ".track-record-content",
      viewOffset: [0, -400],
      animationParams: {
        targets: ".track-record-content__title",
        opacity: [0, 1],
        translateY: [200, 0],
        autoplay: false,
        ...animeJSBaseParams,
      },
    },
    {
      identifier: ".track-record-content",
      viewOffset: [0, -400],
      animationParams: {
        targets: ".track-record-content__cta",
        opacity: [0, 1],
        translateY: [200, 0],
        delay: 100,
        autoplay: false,
        ...animeJSBaseParams,
      },
    },
    {
      identifier: ".chunks-content__grid",
      viewOffset: [0, -200],
      animationParams: {
        targets: ".chunks-content__grid__item",
        opacity: [0, 1],
        translateY: [200, 0],
        delay: function (el, i, l) {
          return i * 100;
        },
        autoplay: false,
        ...animeJSBaseParams,
      },
    },
  ];

  const viewAnimations = useViewAnimations();
  useEffect(() => {
    // startAuroraAnimations([-window.innerWidth / 2, 0], [600 , 0]);
    viewAnimations.init(targets);
  }, []);

  const [aboutUsData, setAboutUsData] = useState<AboutUsDoc | undefined>();
  const { getViewData, isReadyToLoad } = useContext(PreloadContext);

  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext);

  useEffect(() => {
    if (isReadyToLoad) {
      setAboutUsData(getViewData("AboutUs") as AboutUsDoc);
    }
  }, [isReadyToLoad]);

  const { banner, chunks, businessModel, trackRecord } = aboutUsData || {};

  const getRotateStyles = (
    degs: number,
    type: "stick" | "dot-text" | "dot-text-payload" | "dot-text-payload-span",
    level?: "inner" | "outer"
  ) => {
    const styles: React.CSSProperties = {};

    if (type === "dot-text-payload" && !degsIsVertical(degs)) {
      if (degs < 180) {
        styles.left = "100%";
        styles.left = 31;
        styles.top = 0;
      } else {
        styles.right = "100%";
        styles.left = -235;
        styles.top = 6;
      }

      styles.top = "50%";
      styles.left = "50%";
    } else if (type === "dot-text-payload" && degsIsVertical(degs)) {
      styles.top = -20;
    }

    const transformRotateDict = {
      stick: degs,
      "dot-text": -degs,
      "dot-text-payload": 0,
      "dot-text-payload-span": degs,
    };

    styles.transform = `rotate(${transformRotateDict[type]}deg)`;

    const outerTransforms: { [degs: number]: string } = {
      45: `${styles.transform} translate(-40%, -5%)`,
      135: `${styles.transform} translate(-42%, -150%)`,
      225: `${styles.transform} translate(-81%, -109%)`,
      315: `${styles.transform} translate(-80%, 0%)`,
    };

    const innerTransforms: { [degs: number]: string } = {
      0: `${styles.transform} translate(-63%, 55%)`,
      40: `${styles.transform} translate(-60%, 10%)`,
      80: `${styles.transform} translate(-60%, -30%)`,
      120: `${styles.transform} translate(-80%, -95%)`,
      160: `${styles.transform} translate(-40%, -120%)`,
      200: `${styles.transform} translate(-60%, -95%)`,
      240: `${styles.transform} translate(-30%, -120%)`,
      280: `${styles.transform} translate(-35%, -25%)`,
      320: `${styles.transform} translate(-50%, 50%)`,
    };

    if (level === "outer") {
      styles.transform = outerTransforms[degs];
    }
    if (level === "inner") {
      styles.transform = innerTransforms[degs];
    }

    return styles;
  };

  const getRotateStylesMobile = (
    degs: number,
    type: "stick" | "dot-text" | "dot-text-payload" | "dot-text-payload-span"
  ) => {
    const styles: React.CSSProperties = {};

    const transformRotateDict = {
      stick: degs,
      "dot-text": -degs,
      "dot-text-payload": 0,
      "dot-text-payload-span": degs,
    };

    styles.transform = `rotate(${transformRotateDict[type]}deg)`;

    const outerTransforms: { [degs: number]: string } = {
      0: `${styles.transform} translate(-50%, 12%)`,
      90: `${styles.transform} translate(-50%, -55%)`,
      180: `${styles.transform} translate(70%, -120%)`,
      270: `${styles.transform} translate(-45%, -40%)`,
    };

    if (type === "dot-text-payload") {
      styles.transform = outerTransforms[degs];
    }

    console.log(degs, type, styles);

    return styles; 
  };

  const degsIsVertical = (degs: number): boolean => {
    return degs === 0 || degs === 180;
  };

  const getProcessItemStyles = (itemIdx: number): React.CSSProperties => {
    const specialWidths = [null, null, null, null, null, 184, null];
    const specialMargins = [
      null,
      null,
      null,
      "50px 0 0 6px",
      "50px 0 0 -6px",
      null,
      "7px auto 0px",
    ];

    const itemPosition = processGridItemsPositions[itemIdx];

    const itemSpan = processGridItemSpanByPosition[itemPosition];
    const itemWidth =
      specialWidths[itemIdx] ?? (itemPosition === "center" ? 176 : "100%");
    const itemMargin =
      specialMargins[itemIdx] ?? processGridItemMarginByPosition[itemPosition];

    return {
      gridColumn: itemSpan,
      width: itemWidth,
      margin: itemMargin,
    };
  };

  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false);

  //Emit page view event
  useEffect(() => {
    if (aboutUsData && !pageViewSent) {
      //Home data fetched and page view not sent yet
      emitPageView(aboutUsData.seo?.title || "Nexwell Power | About us");

      //Update flag
      setPageViewSent(true);
    }
    // eslint-disable-next-line
  }, [aboutUsData]);

  return aboutUsData ? (
    <div className="about-us-view">
      {aboutUsData?.seo ? <SEOHead {...aboutUsData.seo} /> : <></>}
      <Banner
        textureUrl={banner!.textureUrl}
        background={{
          color: banner!.bgColor,
        }}
        separator={{
          color: "#029839",
        }}
        ringImageUrl={banner!.ringImageUrl!}
        title={banner!.title}
        specificClassNames="about-us"
        subtitle={
          <>
            <p 
              dangerouslySetInnerHTML={{
                __html: banner!.subtitle as string
              }}
            >
            </p>
            <Link
              to="/projects"
              className="cta-anchor"
              style={{
                color: "#029839",
              }}
              onMouseEnter={() => {
                viewAnimations.execute({
                  ...animeJSBaseParams,
                  targets: ".cta-anchor",
                  translateX: [0, "5%"],
                  scale: [1, 1.1],
                  opacity: [1, 0.7],
                });
              }}
              onMouseLeave={() => {
                viewAnimations.execute({
                  ...animeJSBaseParams,
                  targets: ".cta-anchor",
                  translateX: ["5%", 0],
                  scale: [1.1, 1],
                  opacity: [0.7, 1],
                });
              }}
            >
              {banner!.ctaText} <ArrowIcon color="#029839" />
            </Link>
          </>
        }
      />

      <section
        className="our-business-model-section"
        style={{
          backgroundImage: getBackgroundWithOverlay(
            businessModel!.imageUrl,
            businessModel!.overlayAlpha || 0
          ),
        }}
      >
        <h3 className="our-business-model-section__title">
          {businessModel!.title}
        </h3>

        <div className="cycle-container mobile">
          <div className="cycle outer">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/nexwell-dev.appspot.com/o/Power2021%2FAboutUs%2FOval.svg?alt=media&token=2eee72e0-1131-44ee-b653-701a4d1d483d"
              alt="cycle-outer"
            />

            {businessModel!.outer.map((outerItem, index) => (
              <div
                key={`cycle-outer-mobile-${index}`}
                className="stick"
                style={getRotateStylesMobile(outerDegsMobile[index], "stick")}
              >
                <div className="dot">
                  <div
                    className={`
                        dot-text 
                        ${
                          degsIsVertical(
                            outerDegsMobile[index]
                          ) &&
                          `vertical-deg deg${outerDegsMobile[index]}`
                        }
                    `}
                    style={getRotateStylesMobile(
                      outerDegsMobile[index],
                      "dot-text"
                    )}
                    id={`dot-text-${index}`}
                  >
                    <p
                      className={`dot-text-payload`}
                      style={getRotateStylesMobile(
                        outerDegsMobile[index],
                        "dot-text-payload"
                      )}
                    >
                      {formatCycleItemText(outerItem)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="process-container">
            <div
              className="process"
              style={{
                backgroundImage:
                  "url(https://firebasestorage.googleapis.com/v0/b/nexwell-dev.appspot.com/o/Power2021%2FAboutUs%2Fouter-arrows-mobile.svg?alt=media&token=0ac56227-a349-4c33-8cbd-590e63153566)",
              }}
            >
              {businessModel!.inner.map((innerItem, i) => (
                <div
                  key={`process-item-${i}`}
                  className="process__item"
                  style={{
                    ...getProcessItemStyles(i),
                  }}
                >
                  <p className="process__item__payload">
                    {shouldFormat[i]
                      ? formatCycleItemText(innerItem, i)
                      : innerItem}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="cycle-container desktop">
          <div className="cycle outer">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/nexwell-dev.appspot.com/o/Power2021%2FAboutUs%2FExternas.png?alt=media&token=1f5ddc36-f350-46c2-871f-5b75d5629bcd"
              alt="cycle-outer"
            />
            {businessModel!.outer.map((outerItem, index) => (
              <div
                key={`cycle-outer-${index}`}
                className="stick"
                style={getRotateStyles(outerDegs[index], "stick")}
              >
                <div className="dot">
                  <div
                    className={`
                        dot-text 
                        ${
                          degsIsVertical(outerDegs[index]) &&
                          `vertical-deg deg${outerDegs[index]}`
                        }
                    `}
                    style={getRotateStyles(outerDegs[index], "dot-text")}
                    id={`dot-text-${index}`}
                  >
                    <p
                      className={`dot-text-payload`}
                      style={getRotateStyles(
                        outerDegs[index],
                        "dot-text-payload",
                        "outer"
                      )}
                    >
                      {formatCycleItemText(outerItem)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            <div className="cycle inner">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/nexwell-dev.appspot.com/o/Power2021%2FAboutUs%2Finternas.png?alt=media&token=ad4d0262-7cf7-46da-9b6e-dc00f8616aaf"
                alt="cycle-inner"
              />
              {businessModel!.inner.map((innerItem, index) => (
                <div
                  key={`cycle-outer-${index}`}
                  className="stick"
                  style={getRotateStyles(innerDegs[index], "stick")}
                >
                  <div className="dot">
                    <div
                      className={`
                          dot-text 
                          ${
                            degsIsVertical(
                              innerDegs[index]
                            ) &&
                            `vertical-deg deg${innerDegs[index]}`
                          }
                      `}
                      style={getRotateStyles(innerDegs[index], "dot-text")}
                      id={`dot-text-${index}`}
                    >
                      <p
                        className={`dot-text-payload ${innerDegs[index] === 0 ? 'central' : ''}`}
                        style={getRotateStyles(
                          innerDegs[index],
                          "dot-text-payload",
                          "inner"
                        )}
                      >
                        {formatCycleItemText(innerItem)}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="track-record-section">
        <OverlayMedia
          media={{
            type: "image",
            url: "",
            ...(trackRecord?.backgroundMedia ?? {}),
          }}
          containerClassName="track-record-section__media-container"
          mediaClassName="track-record-section__media"
        />
        <div className="track-record-content">
          <p className="track-record-content__title">{trackRecord!.title}</p>
          {/* <Link 
                            className="track-record-content__cta"
                            to="/products"
                        >
                            {trackRecord!.linkText} <ArrowIcon color="#fff" />
                        </Link> */}
        </div>
      </section>

      <section className="chunks-section">
        <div className="chunks-content">
          <div className="chunks-content__grid">
            {chunks?.map((chunkHTML, index) => (
              <div
                key={`chunks-item-${index}`}
                className="chunks-content__grid__item"
                dangerouslySetInnerHTML={{
                  __html: chunkHTML,
                }}
              ></div>
            ))}
          </div>
        </div>
      </section>
    </div>
  ) : null;
};

export default AboutUs;
