//React imports
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
//Local imports
import Banner from "../../components/banner/banner.component";
import SEOHead from "../../components/seo-head/seo-head.component";
import { animeJSBaseParams } from "../../config/animejs.config";
import { AnalyticsContext } from "../../context/analytics.context";
import { PreloadContext } from "../../context/preload.context";
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/animations.hook";
import { usePositions } from "../../hooks/positions.hook";
import { OfferPosition, WorkWithUsDoc } from "../../interfaces/work-with-us";
//Styles
import "./work-with-us.view.scss";

const WorkWithUsView = () => {
  //Contexts
  //Preload context
  const { getViewData } = useContext(PreloadContext);

  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext);

  //Hooks
  //Animation hook
  const { execute, init } = useViewAnimations();

  /**Positions hook */
  const { get: getPositions } = usePositions();

  //State
  //View contents
  const [content, setContent] = useState<WorkWithUsDoc | undefined>(
    getViewData("WorkWithUs") as WorkWithUsDoc
  );

  //Offer positions
  const [positions, setPositions] = useState<OfferPosition[]>(getPositions());

  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false);

  //Emit page view event
  useEffect(() => {
    if (content && !pageViewSent) {
      //Home data fetched and page view not sent yet
      emitPageView(content.seo?.title || "Nexwell Power | Home");

      //Update flag
      setPageViewSent(true);
    }
    // eslint-disable-next-line
  }, [content]);

  //On press releases changes
  useEffect(() => {
    if (getPositions()) setPositions(getPositions());
  }, [getPositions()]);

  //On contents change
  useEffect(() => {
    //Update content in state
    setContent(getViewData("WorkWithUs") as WorkWithUsDoc);

    // eslint-disable-next-line
  }, [getViewData("WorkWithUs")]);

  //On first load
  useEffect(() => {
    //On browser
    if (typeof window !== "undefined")
      window.scrollTo({ top: 0, behavior: "smooth" }); //Scroll to top

    /**Animations targets */
    const targets: AnimationTarget[] = [
      {
        //Positions text
        identifier: ".work-with-us__positions__text",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".work-with-us__positions__text",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Positions title
        identifier: ".work-with-us__positions__title",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".work-with-us__positions__title",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Positions separator
        identifier: ".work-with-us__positions__separator",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".work-with-us__positions__separator",
          autoplay: false,
          opacity: [0, 1],
          delay: 250,
          duration: 1250,
        },
      },
      {
        //Positions table header
        identifier: ".work-with-us__positions__table__header-row__cell",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".work-with-us__positions__table__header-row__cell",
          autoplay: false,
          scale: [0, 1],
          opacity: [0, 1],
          delay: (_e, i, _l) => 150 * i,
        },
      },
      {
        //Positions table row
        identifier: ".work-with-us__positions__table__item-row",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".work-with-us__positions__table__item-row",
          autoplay: false,
          scale: [0, 1],
          opacity: [0, 1],
          delay: (_e, i, _l) => 100 * i,
        },
      },
    ];

    //Animations initialization
    init(targets);
  }, []);

  return (
    <>
      {content?.seo ? <SEOHead {...content.seo} /> : <></>}
      <Banner
        textureUrl={content!.banner.textureUrl}
        background={{
          color: content?.banner.backgroundColor ?? "#72CDD5",
        }}
        ringImageUrl={content?.banner.ringsUrl ?? ""}
        title={
          <div
            className="work-with-us__banner__title"
            dangerouslySetInnerHTML={{
              __html: content?.banner.title ?? "",
            }}
          />
        }
        separator={{
          color: content?.banner.separatorColor,
        }}
        specificClassNames="work-with-us__banner"
        subtitle={
          <div
            className="work-with-us__banner__subtitle"
            dangerouslySetInnerHTML={{
              __html: content?.banner.subtitle ?? "",
            }}
          />
        }
      />
      <section className="work-with-us__positions">
        <div
          className="work-with-us__positions__text"
          dangerouslySetInnerHTML={{
            __html: content?.positions.text ?? "",
          }}
        />
        <div
          className="work-with-us__positions__title"
          dangerouslySetInnerHTML={{
            __html: content?.positions.title ?? "",
          }}
        />
        <hr
          className="work-with-us__positions__separator"
          style={{
            backgroundColor: content?.positions.separatorColor,
          }}
        />
        {positions.length > 0 ? (
          <table className="work-with-us__positions__table">
            <tr className="work-with-us__positions__table__header-row">
              <td className="work-with-us__positions__table__header-row__cell">
                Position
              </td>
              <td className="work-with-us__positions__table__header-row__cell">
                Location
              </td>
              {/* <td className="work-with-us__positions__table__header-row__cell">
                Deadline
              </td> */}
            </tr>
            {positions.map((position) => (
              <tr
                key={`positions__table__position-${position.id}`}
                className="work-with-us__positions__table__item-row"
                id={`position-row-${position.id}`}
                onMouseEnter={() => {
                  execute({
                    ...animeJSBaseParams,
                    targets: `#position-row-${position.id}`,
                    scale: [1, 1.05],
                    translateX: [0, "2.5%"],
                  });
                }}
                onMouseLeave={() => {
                  execute({
                    ...animeJSBaseParams,
                    targets: `#position-row-${position.id}`,
                    scale: [1.05, 1],
                    translateX: ["2.5%", 0],
                  });
                }}
              >
                {position.redirectionLink ? (
                  <>
                    <td className="work-with-us__positions__table__item-row__cell">
                      <a
                        href={position.redirectionLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {position.position}
                      </a>
                    </td>
                    <td className="work-with-us__positions__table__item-row__cell">
                      <a
                        href={position.redirectionLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {position.location}
                      </a>
                    </td>
                    {/* <td className="work-with-us__positions__table__item-row__cell">
                      <a
                        href={position.redirectionLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {position.deadline.toDate().getMonth() + 1}.
                        {position.deadline.toDate().getDate()}.
                        {position.deadline.toDate().getFullYear()}
                      </a>
                    </td> */}
                  </>
                ) : (
                  <>
                    <td className="work-with-us__positions__table__item-row__cell">
                      <a href="mailto:contact@nexwell.com">
                        {position.position}
                      </a>
                    </td>
                    <td className="work-with-us__positions__table__item-row__cell">
                      <a href="mailto:contact@nexwell.com">
                        {position.location}
                      </a>
                    </td>
                    {/* <td className="work-with-us__positions__table__item-row__cell">
                      <a href="mailto:contact@nexwell.com">
                        {position.deadline.toDate().getMonth() + 1}.
                        {position.deadline.toDate().getDate()}.
                        {position.deadline.toDate().getFullYear()}
                      </a>
                    </td> */}
                  </>
                )}
              </tr>
            ))}
          </table>
        ) : (
          <div
            className="work-with-us__positions__empty-message"
            dangerouslySetInnerHTML={{
              __html: content?.positions.emptyMessage ?? "",
            }}
          />
        )}
      </section>
    </>
  );
};

export default WorkWithUsView;
