import { useContext, useEffect, useState } from "react"
import Banner from "../../../components/banner/banner.component"
import OverlayMedia from "../../../components/media/overlay-media.component"
import SEOHead from "../../../components/seo-head/seo-head.component"
import { animeJSBaseParams } from "../../../config/animejs.config"
import { AnalyticsContext } from "../../../context/analytics.context"
import { PreloadContext } from "../../../context/preload.context"
import useViewAnimations, {
  AnimationTarget,
} from "../../../hooks/animations.hook"
import { ESGSocialDoc } from "../../../interfaces/esg/social"
import "./esg-social.view.scss"

const ESGSocial = () => {
  //Contexts
  //Preload context
  const { getViewData } = useContext(PreloadContext)

  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext)

  //Hooks
  //Animation hook
  const { init, execute } = useViewAnimations()

  //State
  //View contents
  const [content, setContent] = useState<ESGSocialDoc | undefined>(
    getViewData("ESGSocial") as ESGSocialDoc
  )

  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false)

  //Emit page view event
  useEffect(() => {
    if (content && !pageViewSent) {
      //Home data fetched and page view not sent yet
      emitPageView(content.seo?.title || "Nexwell Power | ESG Social")

      //Update flag
      setPageViewSent(true)
    }
    // eslint-disable-next-line
  }, [content])

  //On contents change
  useEffect(() => {
    //Update content in state
    setContent(getViewData("ESGSocial") as ESGSocialDoc)

    // eslint-disable-next-line
  }, [getViewData("ESGSocial")])

  //On first load
  useEffect(() => {
    //On browser
    if (typeof window !== "undefined")
      window.scrollTo({ top: 0, behavior: "smooth" }) //Scroll to top

    /**Animations targets */
    const targets: AnimationTarget[] = [
      {
        //Policies list title
        identifier: ".esg-social__content",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".esg-social__content",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
    ]

    //Animations initialization
    init(targets)
  }, [])

  return (
    <main className="esg-social__view">
      {content?.seo ? <SEOHead {...content.seo} /> : <></>}
      <Banner
        textureUrl={content?.banner.textureUrl}
        background={{
          color: content?.banner.backgroundColor ?? "#029839",
        }}
        separator={{
          color: content?.banner.separatorColor ?? "#fff",
        }}
        ringImageUrl={content?.banner.ringImageUrl!}
        title={
          <div
            className="esg-social-banner-title-payload"
            dangerouslySetInnerHTML={{
              __html: content?.banner.title ?? "",
            }}
          ></div>
        }
        specificClassNames="esg-social"
        subtitle={
          <div
            dangerouslySetInnerHTML={{
              __html: content?.banner.subtitle ?? "",
            }}
          ></div>
        }
      />
      <section
        className="esg-social__main"
        style={{
          backgroundColor: content?.mainSection.backgroundColor,
        }}
      >
        <div className="esg-social__content__hideable-texts">
          {content?.mainSection.topTitle?.hide === false && (
            <div
              className="esg-social__content__hideable-texts__title"
              dangerouslySetInnerHTML={{
                __html: content?.mainSection.topTitle?.content ?? "",
              }}
            />
          )}
          {!!content?.mainSection.topText?.hide === false && (
            <div
              className="esg-social__content__hideable-texts__text"
              dangerouslySetInnerHTML={{
                __html: content?.mainSection.topText?.content ?? "",
              }}
            />
          )}
        </div>
        <div className="esg-social__content">
          <OverlayMedia
            media={
              content?.mainSection.backgroundImage ?? {
                type: "image",
                url: "",
                overlayAlpha: 0,
              }
            }
            containerClassName="esg-social__content__image-container"
            mediaClassName="esg-social__content__image-container__media"
            imageAlt="ESG Social"
          />
          <div
            className="esg-social__content__title"
            dangerouslySetInnerHTML={{
              __html: content?.mainSection.title ?? "",
            }}
          />
          <hr
            className="esg-social__content__separator"
            style={{
              backgroundColor: content?.mainSection.separatorColor,
            }}
          />
          <div
            className="esg-social__content__subtitle"
            dangerouslySetInnerHTML={{
              __html: content?.mainSection.subtitle ?? "",
            }}
          />
          <div
            className="esg-social__content__text"
            dangerouslySetInnerHTML={{
              __html: content?.mainSection.text ?? "",
            }}
          />
        </div>
        <div className="esg-social__content__hideable-texts">
          {content?.mainSection.bottomTitle?.hide === false && (
            <div
              className="esg-environmental__content__hideable-texts__title"
              dangerouslySetInnerHTML={{
                __html: content?.mainSection.bottomTitle?.content ?? "",
              }}
            />
          )}
          {!!content?.mainSection.bottomText?.hide === false && (
            <div
              className="esg-environmental__content__hideable-texts__text"
              dangerouslySetInnerHTML={{
                __html: content?.mainSection.bottomText?.content ?? "",
              }}
            />
          )}
        </div>
      </section>
    </main>
  )
}

export default ESGSocial
