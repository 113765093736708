//React imports
// import { lazy } from "react";
//Third parties
import { Redirect, RouteProps } from "react-router-dom";

//Lazy loaded views
/**Home view */
import HomeView from "../views/home/home.view";
// const HomeView = lazy(() => import("../views/home/home.view"));

/**About us view */
import AboutUsView from "../views/about-us/about-us.view";
// const AboutUsView = lazy(() => import("../views/about-us/about-us.view"));

/**Press releases view */
import PressReleasesView from "../views/press-releases/press-releases.view";
// const PressReleasesView = lazy(() => import("../views/press-releases/press-releases.view"));

/**Projects view */
import ProjectsView from "../views/projects/projects.view";
// const ProjectsView = lazy(() => import("../views/projects/projects.view"));

/**Contact us view */
import ContactUsView from "../views/contact-us/contact-us.view";
// const ContactUsView = lazy(() => import("../views/contact-us/contact-us.view"));

/**Work with us view */
import WorkWithUsView from "../views/work-with-us/work-with-us.view";
// const WorkWithUsView = lazy(() => import("../views/work-with-us/work-with-us.view"));

/**Team view  */
import TeamView from "../views/team/team.view";
// const TeamView = lazy(() => import("../views/team/team.view"));

/**ESG view  */
import ESGView from "../views/esg/esg.view";

/**ESG Governance view */
import ESGGovernanceView from "../views/esg/governance/esg-governance.view"

/**ESG Environmental view */
import ESGEnvironmentalView from '../views/esg/environmental/esg-environmental.view';

/**ESG Social view */
import ESGSocialView from '../views/esg/esg-social/esg-social.view';

export const routes: RouteProps[] = [
  {
    path: "/",
    exact: true,
    children: <HomeView />,
  },
  {
    path: "/press-releases",
    exact: true,
    children: <PressReleasesView />,
  },
  {
    path: "/projects",
    exact: true,
    children: <ProjectsView />,
  },
  {
    path: "/about-us",
    exact: true,
    children: <AboutUsView />,
  },
  {
    path: "/team",
    exact: true,
    children: <TeamView />,
  },
  {
    path: "/esg",
    exact: true,
    children: <ESGView />,
  },
  {
    path: "/esg/environmental",
    exact: true,
    children: <ESGEnvironmentalView />,
  },
  {
    path: "/esg/social",
    exact: true,
    children: <ESGSocialView />,
  },
  {
    path: "/esg/governance",
    exact: true,
    children: <ESGGovernanceView />,
  },
  {
    path: "/contact-us",
    exact: true,
    children: <ContactUsView />,
  },
  {
    path: "/work-with-us",
    exact: true,
    children: <WorkWithUsView />,
  },
  {
    path: "*", //404 route
    children: (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    ),
  },
]
