//React imports
import { useContext, useEffect, useState } from "react"
//Third parties imports
import {
  Link,
  RouteComponentProps,
  useLocation,
  withRouter,
} from "react-router-dom"
//Local imports
import Banner from "../../components/banner/banner.component"
import Pagination from "../../components/pagination/pagination.component"
import PressReleaseDetails from "../../components/press-release/press-release-details.component"
import SEOHead from "../../components/seo-head/seo-head.component"
import { animeJSBaseParams } from "../../config/animejs.config"
import { AnalyticsContext } from "../../context/analytics.context"
import { PreloadContext } from "../../context/preload.context"
import useViewAnimations, { AnimationTarget } from "../../hooks/animations.hook"
import usePressReleases from "../../hooks/press-releases.hook"
import { PressRelease, PressReleasesDoc } from "../../interfaces/press-releases"
import { monthsArray } from "../../utils/date.utils"
import { slugifyString } from "../../utils/strings.utils"
//Styles
import "./press-releases.view.scss"

/**Press releases view component */
const PressReleasesView = ({ history }: RouteComponentProps) => {
  //Context
  //Preload context
  const { getViewData, isReadyToLoad } = useContext(PreloadContext)

  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext)

  //Hooks
  /**Query params with locations hook */
  let query = new URLSearchParams(useLocation().search)

  //Press releases hook
  const { get: getPressReleases } = usePressReleases()

  //Animations hook
  const { execute, init } = useViewAnimations()

  //Data
  /**Disclaimer text */
  const disclaimer: string =
    "* This press release has been prepared for the purpose of announcing to the public certain activities of Nexwell Power and the Nexwell Group, and not for the purpose of soliciting any investment. This press release does not constitute any offer or solicitation to purchase or subscribe any security, including but not limited to notes referenced herein, in any jurisdiction."

  /**Query param for release */
  const titleQuery = query.get("title")

  /**Pagination page size */
  const pageSize = 2

  //State
  //Press releases
  const [pressReleases, setPressReleases] = useState<PressRelease[]>(
    getPressReleases()
  )

  //Current page
  const [currentPage, setCurrentPage] = useState(0)

  //Curent selected press release
  const [selectedPressRelease, setSelectedPressRelease] =
    useState<PressRelease | null>(null)

  //Last selected press release
  const [lastSelectedPressRelease, setLastSelectedPressRelease] =
    useState<PressRelease | null>(null)

  //Page view sent flag
  // const [pageViewSent, setPageViewSent] = useState(false);

  //Content
  const [content, setContent] = useState<PressReleasesDoc>(
    getViewData("PressReleases") as PressReleasesDoc
  )

  //Location keys for forward backwards clicks detection
  const [locationKeys, setLocationKeys] = useState<(string | undefined)[]>([])

  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false)

  //History listener
  const [listener, setListener] = useState<() => {}>()

  //Mehotds
  /**Clean history listener */
  const cleanHistoryListener = () => {
    if (listener) listener()
  }

  //Emit page view event
  useEffect(() => {
    if (content && !pageViewSent) {
      //Home data fetched and page view not sent yet
      emitPageView(content.seo?.title || "Nexwell Power | Press releases")

      //Update flag
      setPageViewSent(true)
    }
    // eslint-disable-next-line
  }, [content])

  //On selected press release and location keys changes
  useEffect(() => {
    if (selectedPressRelease) {
      //New selected, change last selected
      setLastSelectedPressRelease(selectedPressRelease)
    }

    //Clean previous listener
    cleanHistoryListener()

    /**New history listener */
    const newListener = history.listen((location) => {
      if (history.action === "PUSH") {
        //PUSH action
        setLocationKeys([location.key])

        if (selectedPressRelease) {
          //Push comes from menu click
          setSelectedPressRelease(null)
        }
      } else if (history.action === "POP") {
        //POP action
        if (locationKeys[1] === location.key) {
          //Popping to go forward
          //Update location keys
          setLocationKeys(([_, ...keys]) => keys) //Pop first

          if (selectedPressRelease) {
            //Going forward on a selected press release
            setSelectedPressRelease(null)
          } else if (!selectedPressRelease && lastSelectedPressRelease) {
            //Going forward from all releases
            setSelectedPressRelease(lastSelectedPressRelease)
          }
        } else {
          //Popping to go backwards
          //Update location keys
          setLocationKeys((keys) => [location.key, ...keys])

          if (selectedPressRelease) {
            //Go back from press release
            setSelectedPressRelease(null)
          } else {
            //Go back after menu item click
            setSelectedPressRelease(lastSelectedPressRelease)
          }
        }
      }

      // console.log(locationKeys, location.key);
    })

    //Update listener in state
    setListener(() => newListener)
  }, [locationKeys, selectedPressRelease, lastSelectedPressRelease])

  //On press releases changes
  useEffect(() => {
    //Update press releases with new data
    setPressReleases(getPressReleases())
  }, [getPressReleases()])

  //On is ready to load and content changes
  useEffect(() => {
    if (isReadyToLoad) {
      setContent(getViewData("PressReleases") as PressReleasesDoc)
    }
    // eslint-disable-next-line
  }, [isReadyToLoad, getViewData("PressReleases")])

  useEffect(() => {
    //Initialization scroll
    if (window) window.scrollTo(0, 0)

    /**Animation targets */
    const targets: AnimationTarget[] = [
      {
        //Press releases container slide in
        identifier: ".press-releases__releases",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".press-releases__releases",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Press releases right image flip in
        identifier: ".press-releases__releases__right__image-container",
        midPointOffset: -0.25,
        animationParams: {
          ...animeJSBaseParams,
          targets: ".press-releases__releases__right__image-container",
          autoplay: false,
          rotateY: ["90deg", "0deg"],
          rotateZ: ["-12deg", "0deg"],
        },
      },
    ]

    //Animations initialization
    init(targets)
  }, [])

  //On items change
  useEffect(() => {
    if (titleQuery && pressReleases) {
      //If first not done yet, requested a release via query params and releases are fetched
      setSelectedPressRelease(
        pressReleases.find(
          (release) => slugifyString(release.title) === titleQuery
        ) ?? null
      )
    }
  }, [pressReleases, titleQuery])

  return (
    <>
      {content?.seo ? <SEOHead {...content.seo} /> : <></>}
      <Banner
        textureUrl={content!.banner.textureUrl}
        specificClassNames={`press-releases__banner${
          selectedPressRelease ? " small" : ""
        }`}
        background={{
          color: content?.banner.backgroundColor,
        }}
        separator={{
          color: content?.banner.separatorColor || "#ffffff",
        }}
        ringImageUrl={content?.banner.ringsUrl ?? ""}
        // backgroundImageOverlayAlpha={newsData?.banner.overlayAlpha}
        // backgroundImage={newsData?.banner.backgroundImageUrl ?? ""}
        title={
          !selectedPressRelease ? (
            <div
              className="press-releases__banner__title"
              dangerouslySetInnerHTML={{
                __html: content?.banner.title ?? "",
              }}
            />
          ) : undefined
        }
        subtitle={
          !selectedPressRelease ? (
            <div
              className="press-releases__banner__subtitle"
              dangerouslySetInnerHTML={{
                __html: content?.banner.subtitle ?? "",
              }}
            />
          ) : undefined
        }
        bottomText={
          pressReleases && !selectedPressRelease && pressReleases.length > 1 ? ( //If more than 1
            <div
              className="press-releases__banner__highlighted-release"
              onClick={() => {
                //Scroll to top smoothly
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                })
              }}
              id={`press-releases__releases__banner__release`}
              onMouseEnter={() => {
                execute({
                  ...animeJSBaseParams,
                  targets: `#press-releases__releases__banner__release`,
                  scale: [1, 1.1],
                  translateX: [0, "5%"],
                })
              }}
              onMouseLeave={() => {
                execute({
                  ...animeJSBaseParams,
                  targets: `#press-releases__releases__banner__release`,
                  scale: [1.1, 1],
                  translateX: ["5%", 0],
                })
              }}
            >
              <Link
                to={`/press-releases?title=${slugifyString(
                  pressReleases[0].title
                )}`}
              >
                <h3 className="press-releases__banner__highlighted-release__date">
                  {
                    monthsArray[
                      (
                        pressReleases[0]
                          .releaseDate as firebase.default.firestore.Timestamp
                      )
                        .toDate()
                        .getMonth()
                    ]
                  }
                  ,{" "}
                  {(
                    pressReleases[0]
                      .releaseDate as firebase.default.firestore.Timestamp
                  )
                    .toDate()
                    .getFullYear()}
                </h3>
                <p className="press-releases__banner__highlighted-release__title">
                  {pressReleases[0].title}
                </p>
                <div
                  className="press-releases__banner__highlighted-release__preview"
                  dangerouslySetInnerHTML={{
                    __html: `<p><i>${
                      pressReleases[0].subtitle
                    }</i> -- ${pressReleases[0].content.substr(0, 150)}...</p>`,
                  }}
                />
              </Link>
            </div> //Becomes most recent
          ) : undefined //Else don't show
        }
      />
      {selectedPressRelease ? (
        <PressReleaseDetails
          release={selectedPressRelease}
          goBackCallback={() => setSelectedPressRelease(null)}
          {...(content?.details ?? {
            aboutsPaginationColor: "#FECA2F",
            aboutsTitleColor: "#99C21D",
            disclaimerColor: "#99C21D",
            goBackColor: "#FECA2F",
            separatorsColor: "#5B6770",
            titleColor: "#029839",
            disclaimerText: disclaimer,
          })}
        />
      ) : (
        <div className="press-releases__releases">
          {pressReleases ? (
            <>
              {pressReleases.length > 1 ? (
                <>
                  <div
                    className="press-releases__releases__desktop-hidden"
                    onClick={() => {
                      //Scroll to top smoothly
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      })
                    }}
                  >
                    <Link
                      to={`/press-releases?title=${slugifyString(
                        pressReleases[0].title
                      )}`}
                    >
                      <h4 className="press-releases__releases__desktop-hidden__date">
                        {
                          monthsArray[
                            (
                              pressReleases[0]
                                .releaseDate as firebase.default.firestore.Timestamp
                            )
                              .toDate()
                              .getMonth()
                          ]
                        }
                        ,{" "}
                        {(
                          pressReleases[0]
                            .releaseDate as firebase.default.firestore.Timestamp
                        )
                          .toDate()
                          .getFullYear()}
                      </h4>
                      <p className="press-releases__releases__desktop-hidden__title">
                        {pressReleases[0].title}
                      </p>
                      <div
                        className="press-releases__releases__desktop-hidden__preview"
                        dangerouslySetInnerHTML={{
                          __html: `<p>${
                            pressReleases[0].subtitle
                          } -- ${pressReleases[0].content.substr(
                            0,
                            150
                          )}...</p>`,
                        }}
                      />
                    </Link>
                  </div>
                  {pressReleases.length > 2 ? (
                    <hr
                      className="press-releases__releases__separator mobile"
                      style={{
                        backgroundColor: content?.releases.separatorColor,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              {(pressReleases.length <= 1
                ? pressReleases
                : pressReleases.slice(
                    1 + currentPage * pageSize, //Page start leaping
                    1 + currentPage * pageSize + pageSize //Pagen end leaping first
                  )
              ).map((release, i) => (
                <>
                  {i > 0 ? (
                    <hr
                      key={`press-releases-separator-${i}`}
                      className="press-releases__releases__separator"
                      style={{
                        backgroundColor: content?.releases.separatorColor,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  <div
                    key={`press-releases-release-${release.id}`}
                    className="press-releases__releases__item"
                    onClick={() => {
                      //Scroll to top smoothly
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      })
                    }}
                    id={`press-releases__releases__release__${release.id}`}
                    onMouseEnter={() => {
                      execute({
                        ...animeJSBaseParams,
                        targets: `#press-releases__releases__release__${release.id}`,
                        scale: [1, 1.1],
                        translateX: [0, "5%"],
                      })
                    }}
                    onMouseLeave={() => {
                      execute({
                        ...animeJSBaseParams,
                        targets: `#press-releases__releases__release__${release.id}`,
                        scale: [1.1, 1],
                        translateX: ["5%", 0],
                      })
                    }}
                  >
                    <Link
                      to={`/press-releases?title=${slugifyString(
                        release.title
                      )}`}
                    >
                      <h4 className="press-releases__releases__item__date">
                        {
                          monthsArray[
                            (
                              release.releaseDate as firebase.default.firestore.Timestamp
                            )
                              .toDate()
                              .getMonth()
                          ]
                        }
                        ,{" "}
                        {(
                          release.releaseDate as firebase.default.firestore.Timestamp
                        )
                          .toDate()
                          .getFullYear()}
                      </h4>
                      <p className="press-releases__releases__item__title">
                        {release.title}
                      </p>
                      <div
                        className="press-releases__releases__item__preview"
                        dangerouslySetInnerHTML={{
                          __html: `<p><i>${
                            release.subtitle
                          }</i> -- ${release.content.substr(0, 150)}...</p>`,
                        }}
                      />
                    </Link>
                  </div>
                </>
              ))}
              {pressReleases.length > 0 ? (
                <div className="press-releases__releases__pagination">
                  <Pagination
                    pages={Math.ceil((pressReleases.length - 1) / pageSize)}
                    onClickHandler={(page) => setCurrentPage(page)}
                    color={content?.releases.paginationColor}
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  )
}

export default withRouter(PressReleasesView)
