import { PDFPageProxy } from 'pdfjs-dist'
import { RenderParameters } from 'pdfjs-dist/types/src/display/api'
import { useEffect, useRef } from 'react'
import './pdf-viewer-page-preview.component.scss'

const PdfViewerPagePreview = ({
  page,
  currentPage,
  onClickCallback,
}: {
  page: PDFPageProxy
  currentPage: boolean
  onClickCallback: () => void
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (canvasRef.current) {
      const viewport = page.getViewport({ scale: 1 })

      // Prepare canvas using PDF page dimensions.
      const canvas = canvasRef.current
      const canvasContext = canvas.getContext("2d")
      canvas.height = viewport.height
      canvas.width = viewport.width

      // Render PDF page into canvas context.
      const renderContext: RenderParameters = {
        canvasContext: canvasContext!,
        viewport,
      }

      page
        .render(renderContext)
        .promise.then(() => {
        })
        .catch((err) => {
          console.log("Page preview", page.pageNumber, "couldn't render", err)
        })
    }
  }, [page, canvasRef.current])

  return (
    <div className="pdf-viewer__page-preview__container">
      <div
        onClick={onClickCallback}
        className={`pdf-viewer__page-preview__container__page ${
          currentPage ? "active" : ""
        }`}
      >
        <canvas ref={canvasRef}></canvas>
      </div>
      <p className="pdf-viewer__page-preview__container__number">
        {page.pageNumber}
      </p>
    </div>
  )
}

export default PdfViewerPagePreview