//React imports
import { useContext, useEffect, useState } from "react";
//Third parties imports
import { Link, useLocation } from "react-router-dom";
//Local imports
import { HeaderDoc, NavigationItem } from "../../interfaces/header";
import { PreloadContext } from "../../context/preload.context";
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/animations.hook";
import { animeJSBaseParams } from "../../config/animejs.config";
//Assets
import { ReactComponent as MenuBurger } from "../../assets/icons/menu_burger.svg";
//Styles
import "./header.component.scss"
import DropdownArrow from "../arrow/dropdown-arrow.component";

//Child components
/**Navigation item */
const HeaderNavigationItem = ({
  item,
  position,
  callback,
}: {
  item: NavigationItem
  position: number
  callback?: () => void
}) => {
  //Hooks
  //React location hook
  const { pathname } = useLocation()

  //State
  //Has children routes
  const [hasChildren, setHasChildren] = useState(!!item.children?.length)

  //On props change
  useEffect(() => {
    setHasChildren(!!item.children?.length)
  }, [item.children?.length])

  return (
    <div className="header__container__items__item">
      <Link
        key={`header-item-${item.route}-${position}`}
        className={`header__container__items__item--link${
          pathname.startsWith(item.route) ? " active-link" : ""
        }`}
        onClick={callback}
        to={item.route}
      >
        {item.name}
        {hasChildren && <DropdownArrow />}
      </Link>

      {hasChildren && (
        <ul className="header__container__items__item--children">
          {item.children?.map(({ name, route }) => (
            <li key={`header-item-${route}-child-${route}`}>
              <Link
                to={route}
                onClick={callback}
                className={`header__container__items__item--children__child${
                  pathname.startsWith(route) ? " active-link" : ""
                }`}
              >
                {name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

const Header = () => {
  //Contexts
  //Preload context
  const { getViewData, isReadyToLoad } = useContext(PreloadContext);

  //Hooks
  //Animations hook
  const { init } = useViewAnimations();

  //Data
  /**Show mobile flag */
  const [showMobile, setShowMobile] = useState(false);

  //Data
  //Content
  const [content, setContent] = useState<HeaderDoc | undefined>(
    getViewData("Header") as HeaderDoc | undefined
  );

  //On is ready to load
  useEffect(() => {
    if (isReadyToLoad) {
      setContent(getViewData("Header") as HeaderDoc | undefined);
    }
    // eslint-disable-next-line
  }, [isReadyToLoad, getViewData("Header")]);

  //On first load of component
  useEffect(() => {
    /**Animation targets */
    const targets: AnimationTarget[] = [
      {
        //Logo slide in
        identifier: ".header__container__logo",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".header__container__logo",
          done: true,
          translateX: ["-100vw", 0],
          delay: (_el, i, _l) => 100 * i,
        },
      },
      {
        //Items slide in
        identifier: ".header__container__items__item",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".header__container__items__item",
          done: true,
          translateX: ["-100vw", 0],
          delay: (_el, i, _l) => 100 * i,
        },
      },
    ];

    //Initialize animations
    init(targets);
    // eslint-disable-next-line
  }, []);

  return (
    <header className="header__container">
      <Link to="/" className="header__container__logo">
        <img
          alt="Nexwell Group"
          src={content?.logoUrl}
          width="100"
          height="84"
        />
      </Link>
      <div
        className={`header__container__items ${showMobile ? "show" : "hide"}`}
      >
        <div className={"header__container__items__item home"}>
          <Link
            to="/"
            onClick={() => setShowMobile(false)}
            className="header__container__logo"
          >
            <img
              alt="Nexwell Group"
              src={content?.logoUrl}
              width="100"
              height="84"
            />
          </Link>
          <div className="close" onClick={() => setShowMobile(false)}>
            X
          </div>
        </div>
        {(content?.items || []).map((navItem, i) => (
          <HeaderNavigationItem
            key={`header-navigation__item--${i}`}
            item={navItem}
            position={i}
            callback={() => setShowMobile(false)}
          />
        ))}
      </div>
      <MenuBurger
        onClick={() => setShowMobile(!showMobile)}
        className="header__container__burger"
      />
    </header>
  )
};

export default Header;
