//Third parties imports
import {
  GoogleApiWrapper,
  IInfoWindowProps,
  IMarkerProps,
  InfoWindow,
  IProvidedProps,
  Map,
  Marker,
} from "google-maps-react";
import { PropsWithChildren, useEffect, useState } from "react";
import { animeJSBaseParams } from "../../config/animejs.config";
import { gcloudCredentials } from "../../config/gcloud.config";
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/animations.hook";
//Styles
import "./location-card.component.scss";

//Declarations
/**Location card props */
interface LocationCardProps extends PropsWithChildren<IProvidedProps> {
  /**Location to render */
  location: {
    /**Coords */
    coords: firebase.default.firestore.GeoPoint;

    /**Country or state */
    title: string;

    /**Address line */
    address: string;
  };

  /**Separator color */
  separatorColor: string;

  /**Location index */
  index: number;
}

/**Location card component */
const LocationCard = ({
  location,
  separatorColor,
  google,
  index: i,
}: LocationCardProps) => {
  //Hooks
  //Animations hook
  const { init } = useViewAnimations();

  //Data
  //Marker state
  const [{ showingInfoWindow, activeMarker }, setMarkerState] = useState({
    showingInfoWindow: false,
    activeMarker: null,
  });

  //On first render
  useEffect(() => {
    /**Animation targets */
    const targets: AnimationTarget[] = [
      {
        //Map
        identifier: `#location-${i}-map`,
        midPointOffset: 0,
        animationParams: {
          ...animeJSBaseParams,
          autoplay: false,
          targets: `#location-${i}-map`,
          scale: [0.5, 1],
          opacity: [0, 1],
        },
      },
      {
        //Address
        identifier: `#location-${i}-address`,
        animationParams: {
          ...animeJSBaseParams,
          autoplay: false,
          targets: `#location-${i}-address`,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Separator
        identifier: `#location-${i}-separator`,
        animationParams: {
          ...animeJSBaseParams,
          autoplay: false,
          targets: `#location-${i}-separator`,
          opacity: [0, 1],
          delay: 250,
          duration: 1250,
        },
      },
    ];

    //Initialize animations
    init(targets);
  }, []);

  return (
    <div className="location-card">
      <div id={`location-${i}-map`} className="location-card__maps">
        <Map
          google={google}
          initialCenter={{
            lat: location.coords.latitude,
            lng: location.coords.longitude,
          }}
          containerStyle={{
            windth: "100%",
            height: "275px",
          }}
          onClick={() =>
            setMarkerState({
              showingInfoWindow: false,
              activeMarker: null,
            })
          }
        >
          <Marker
            onClick={(_props, marker, _e) =>
              setMarkerState({
                showingInfoWindow: true,
                activeMarker: marker,
              })
            }
            {...({
              position: {
                lat: location.coords.latitude,
                lng: location.coords.longitude,
              },
            } as IMarkerProps)}
          />
          <InfoWindow
            {...({
              marker: activeMarker,
              visible: showingInfoWindow,
            } as IInfoWindowProps)}
          >
            <div>
              <p>{location.address}</p>
            </div>
          </InfoWindow>
        </Map>
      </div>
      <div className="location-card__content">
        <div className="location-card__content__separator-container">
          <hr
            id={`location-${i}-separator`}
            style={{
              backgroundColor: separatorColor,
            }}
            className="location-card__content__separator-container__separator"
          />
        </div>
        <div
          id={`location-${i}-address`}
          className="location-card__content__address"
        >
          <p className="location-card__content__address__title">
            {location.title}
          </p>
          <p className="location-card__content__address__line">
            {location.address}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: gcloudCredentials.MAPS_API_KEY,
})(LocationCard);
