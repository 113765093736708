//React imports
import React, { useEffect, useState } from "react";
//Local imports
import useContent from "../hooks/content.hook";
import useImagePreloader from "../hooks/image-preloader.hook";
import { AboutUsDoc } from "../interfaces/about-us";
import { HomeDoc } from "../interfaces/home";
import { AnyDoc, DocId } from "../interfaces/shared";
import { TeamDoc } from "../interfaces/team";
import { ProjectsDoc } from "./../interfaces/projects";
import { PressReleasesDoc } from "./../interfaces/press-releases";
import { ContactUsDoc } from "./../interfaces/contact-us";
import { FooterDoc } from "./../interfaces/footer";
import { WorkWithUsDoc } from "./../interfaces/work-with-us";
import { ESGDocV2 } from "../interfaces/esg";
import { ESGEnvironmentalDoc } from "../interfaces/esg/environmental";
import { ESGGovernanceDoc } from "../interfaces/esg/governance";

//Definitions
/**Context functions */
interface PreloadContextProviderValues {
  /**Get a specific view data */
  getViewData: (id: DocId) => AnyDoc | undefined;

  /**Is ready to load flag */
  isReadyToLoad: boolean;
}

/**Preload context */
export const PreloadContext = React.createContext<PreloadContextProviderValues>(
  {
    getViewData: () => undefined,
    isReadyToLoad: true,
  }
);

const PreloadProvider = ({ children }: { children: any }) => {
  //Hooks
  /**All documents snap shot */
  const allDocsSnap: AnyDoc[] = useContent().getAll();

  //Preload hook
  const { bulkPreload } = useImagePreloader();

  //Data
  //All images loaded flag
  const [allImagesLoaded, setAllImagesLoded] = useState(false);

  //Methods
  /**Get view data */
  const getViewData = (id: DocId): AnyDoc | undefined =>
    allDocsSnap.find((doc) => (doc as any).contentName === id);

  /**Preload images */
  const preloadImages = async () => {
    if (allDocsSnap) {
      /**Home data */
      const homeData = getViewData("Home")! as HomeDoc

      /**About us data */
      const aboutUsData = getViewData("AboutUs")! as AboutUsDoc

      /**Platforms data */
      const projectsData = getViewData("Projects")! as ProjectsDoc

      /**Press releases data */
      const pressReleasesData = getViewData(
        "PressReleases"
      )! as PressReleasesDoc

      /**Team data */
      const teamData = getViewData("Team")! as TeamDoc

      /**News data */
      const contactUsData = getViewData("ContactUs")! as ContactUsDoc

      /**ESG data */
      const esgData = getViewData("ESGV2")! as ESGDocV2

      /**ESG Environmental data */
      const esgEnvironmentalData = getViewData(
        "ESGEnvironmental"
      )! as ESGEnvironmentalDoc

      /**ESG Social data */
      const esgSocialData = getViewData(
        "ESGSocial"
      )! as ESGEnvironmentalDoc

      /**ESG Governance data */
      const esgGovernanceData = getViewData(
        "ESGGovernance"
      )! as ESGGovernanceDoc

      /**Footer data */
      const footerData = getViewData("Footer")! as FooterDoc

      /**News data */
      const workWithUsData = getViewData("WorkWithUs")! as WorkWithUsDoc

      /**Images url array */
      let urls: string[] = [
        homeData.banner.textureUrl,
        aboutUsData.banner.textureUrl,
        projectsData.banner.textureUrl,
        pressReleasesData.banner.textureUrl,
        teamData.banner.textureUrl,
        contactUsData.banner.textureUrl,
        esgData.banner.textureUrl,
        workWithUsData.banner.textureUrl,
        esgEnvironmentalData.banner.textureUrl,
        esgSocialData.banner.textureUrl,
        esgGovernanceData.banner.textureUrl,
        footerData.backgroundImage?.mobile,
        footerData.backgroundImage?.desktop,
      ]

      while (urls.length > 0) {
        // console.log(urls);

        //While there are images needed of loading
        /**Preload reults */
        const results = await bulkPreload(urls)

        /**Successfull indexes */
        const successfullIndexes = results
          .map((res, i) => (res ? i : null))
          .filter((idx) => idx !== null)

        //Remove urls, allow to stay only those that are not in the successful indexes
        urls = urls.filter((_, i) => successfullIndexes.indexOf(i) === -1)

        // console.log(urls);
      }

      //Set all images as loaded
      setAllImagesLoded(true)
    }
  };

  //Use effect
  useEffect(() => {
    if (!allImagesLoaded && allDocsSnap) {
      //Images not laoded yet and data from firestore fetched
      //Do preload images
      preloadImages();
    }
    // eslint-disable-next-line
  }, [allDocsSnap]);

  return (
    <PreloadContext.Provider
      value={{
        getViewData,
        isReadyToLoad: allDocsSnap && allImagesLoaded,
      }}
    >
      {children}
    </PreloadContext.Provider>
  );
};

export { PreloadProvider };
