//Third parties imports
import { useFirestore, useFirestoreCollectionData } from "reactfire";
//Local imports
import { Project } from "../interfaces/projects";
import { getEnvironment } from "../utils/environment.utils";

/**Projects hook */
export const useProjectsHook = (limit?: number) => {
  /**Firestore reference */
  const firestore = useFirestore();

  /**Projects query */
  const projectsQuery = firestore
    .collection("NexwellPower2021")
    .doc(getEnvironment())
    .collection("Projects")
    .where("deleted", "==", false) //Not deleted
    .where("active", "==", true) //Active
    .orderBy("priority", "desc") //Higher priority first
    .orderBy("createdAt", "desc"); //First created

  /**Projects query data */
  const { data } = useFirestoreCollectionData<Project>(
    limit && limit > 0 ? projectsQuery.limit(limit) : projectsQuery,
    {
      idField: "id",
      initialData: [],
    }
  );

  /**Get docs of result */
  const get = (): Project[] => data;

  return {
    get,
  };
};
