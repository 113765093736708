//React imports
import { useContext, useEffect, useState } from "react";
import Banner from "../../components/banner/banner.component";
import { LocationCard } from "../../components/location-card";
import SEOHead from "../../components/seo-head/seo-head.component";
import SubmissionForm, {
  SubmissionFormFields,
} from "../../components/submission-form/submission-form.component";
//Local imports
import { animeJSBaseParams } from "../../config/animejs.config";
import { AnalyticsContext } from "../../context/analytics.context";
import { PreloadContext } from "../../context/preload.context";
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/animations.hook";
import { useSubmissions } from "../../hooks/submissions.hook";
import { ContactUsDoc } from "../../interfaces/contact-us";
//Styles
import "./contact-us.view.scss";

const ContactUsView = () => {
  //Contexts
  //Preload context
  const { getViewData } = useContext(PreloadContext);

  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext);

  //Hooks
  //Animation hook
  const { execute, init } = useViewAnimations();

  //Submissions hook
  const { generateSubmission } = useSubmissions();

  //State
  //View contents
  const [content, setContent] = useState<ContactUsDoc | undefined>(
    getViewData("ContactUs") as ContactUsDoc
  );

  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false);

  //For developers flag
  const [forDevelopers, setForDevelopers] = useState(false);

  //Methods
  /**Send submission */
  const sendSubmission = async (data: SubmissionFormFields) => {
    //Send submission with form data
    await generateSubmission({
      ...data,
    });

    //Get our of for developers
    setForDevelopers(false);

    //Scroll smoothly to top
    if (typeof window !== "undefined")
      window.scrollTo({ top: 0, behavior: "smooth" }); //Scroll to top
  };

  //Emit page view event
  useEffect(() => {
    if (content && !pageViewSent) {
      //Home data fetched and page view not sent yet
      emitPageView(content.seo?.title || "Nexwell Power | Contact us");

      //Update flag
      setPageViewSent(true);
    }
    // eslint-disable-next-line
  }, [content]);

  //On contents change
  useEffect(() => {
    //Update content in state
    setContent(getViewData("ContactUs") as ContactUsDoc);

    // eslint-disable-next-line
  }, [getViewData("ContactUs")]);

  //On for developers change
  useEffect(() => {
    if (forDevelopers) {
      //Entering into contact us developers view

      /**Animations targets */
      const targets: AnimationTarget[] = [
        {
          //Developers banner title
          identifier: ".contact-us__developers__banner__title",
          animationParams: {
            ...animeJSBaseParams,
            targets: ".contact-us__developers__banner__title",
            autoplay: false,
            translateX: ["-100vw", 0],
          },
        },
        {
          //Developers banner separator
          identifier: ".contact-us__developers__banner__separator",
          animationParams: {
            ...animeJSBaseParams,
            targets: ".contact-us__developers__banner__separator",
            autoplay: false,
            opacity: [0, 1],
            delay: 250,
            duration: 1500,
          },
        },
        {
          //Developers banner text
          identifier: ".contact-us__developers__banner__text",
          animationParams: {
            ...animeJSBaseParams,
            targets: ".contact-us__developers__banner__text",
            autoplay: false,
            translateX: ["-100vw", 0],
          },
        },
      ];

      //Animations initialization
      init(targets);
    }
  }, [forDevelopers]);

  //On first load
  useEffect(() => {
    //On browser
    if (typeof window !== "undefined")
      window.scrollTo({ top: 0, behavior: "smooth" }); //Scroll to top
  }, []);

  return (
    <>
      {content?.seo ? <SEOHead {...content.seo} /> : <></>}
      <Banner
        textureUrl={content!.banner.textureUrl}
        specificClassNames={`contact-us__banner${
          forDevelopers ? " small" : ""
        }`}
        background={{
          color: content?.banner.backgroundColor ?? "##FECA2F",
        }}
        ringImageUrl={content?.banner.ringsUrl ?? ""}
        separator={
          forDevelopers
            ? undefined
            : {
                color: content?.banner.separatorColor,
              }
        }
        title={
          forDevelopers ? undefined : (
            <div
              className="contact-us__banner__title"
              dangerouslySetInnerHTML={{
                __html: content?.banner.title ?? "",
              }}
            />
          )
        }
        subtitle={
          forDevelopers ? undefined : (
            <div className="contact-us__banner__emails">
              {(content?.banner.emails ?? []).map((email, i) => (
                <p
                  key={`contact-us__emails__email-${i}`}
                  onClick={() => setForDevelopers(true)}
                  className="contact-us__banner__emails__item"
                  id={`contact-us__email-${i}`}
                  onMouseEnter={() => {
                    execute({
                      ...animeJSBaseParams,
                      targets: `#contact-us__email-${i}`,
                      translateX: [0, "5%"],
                      scale: [1, 1.1],
                      opacity: [1, 0.7],
                    });
                  }}
                  onMouseLeave={() => {
                    execute({
                      ...animeJSBaseParams,
                      targets: `#contact-us__email-${i}`,
                      translateX: ["5%", 0],
                      scale: [1.1, 1],
                      opacity: [0.7, 1],
                    });
                  }}
                >
                  {email}
                </p>
              ))}
            </div>
          )
        }
      />
      {forDevelopers ? (
        <section className="contact-us__developers">
          <div className="contact-us__developers__banner">
            <div
              className="contact-us__developers__banner__title"
              dangerouslySetInnerHTML={{
                __html: content?.developers.title ?? "",
              }}
            />
            <hr
              style={{
                backgroundColor: content?.developers.separatorColor,
              }}
              className="contact-us__developers__banner__separator"
            />
            <div
              className="contact-us__developers__banner__text"
              dangerouslySetInnerHTML={{
                __html: content?.developers.text ?? "",
              }}
            />
          </div>
          <div className="contact-us__developers__form">
            <SubmissionForm
              onSubmit={sendSubmission}
              submitColor={content?.developers.submitColor ?? "#029839"}
            />
          </div>
        </section>
      ) : (
        <section className="contact-us__addresses">
          {(content?.addresses.locations ?? []).map((location, i) => (
            <div
              key={`contact-us__location-${i}`}
              className="contact-us__addresses__item"
            >
              <LocationCard
                index={i}
                location={location}
                separatorColor={content?.addresses.separatorsColor ?? "#F7A800"}
              />
            </div>
          ))}
        </section>
      )}
    </>
  );
};

export default ContactUsView;
