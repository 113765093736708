//React imports
import { useEffect, useState } from "react"
//Local imports
import { PressRelease as PressReleaseInterface } from "../../interfaces/press-releases"
import { animeJSBaseParams } from "../../config/animejs.config"
import useViewAnimations, { AnimationTarget } from "../../hooks/animations.hook"
import ArrowIcon from "../arrow/arrow.component"
import Pagination from "../pagination/pagination.component"
//Styles
import "./press-release-details.component.scss"
import { Link } from "react-router-dom"

//Declarations
/**Press release props */
interface PressReleaseProps {
  release: PressReleaseInterface
  goBackCallback: () => void

  /**Go back link color */
  goBackColor: string

  /**Releases title color */
  titleColor: string

  /**Abouts title color */
  aboutsTitleColor: string

  /**Abouts pagination color */
  aboutsPaginationColor: string

  /**Disclaimer text color */
  disclaimerColor: string

  /**Separators colors */
  separatorsColor: string

  /**Default disclaimer text */
  defaultDisclaimerText: string
}

const PressReleaseDetails = ({
  release,
  goBackCallback,
  aboutsPaginationColor,
  aboutsTitleColor,
  disclaimerColor,
  goBackColor,
  separatorsColor,
  titleColor,
  defaultDisclaimerText,
}: PressReleaseProps) => {
  //Hooks
  //Animations hook
  const { execute, init } = useViewAnimations()

  //Data
  /**Abouts page size */
  const pageSize = 2

  /**Abouts mobile page size */
  const mobilePageSize = 1

  //State
  //Current abouts page
  const [currentPage, setCurrentPage] = useState(0)

  //Mobile current page
  const [mobileCurrentPage, setMobileCurrentPage] = useState(0)

  //On first render
  useEffect(() => {
    /**Animation targets */
    const targets: AnimationTarget[] = [
      {
        //Go back link
        identifier: ".press-release__top__back",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".press-release__top__back",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Press release title
        identifier: ".press-release__top__title",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".press-release__top__title",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Press release subtitle
        identifier: ".press-release__top__subtitle",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".press-release__top__subtitle",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Press release content
        identifier: ".press-release__content__left",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".press-release__content__left",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Press release disclaimer
        identifier: ".press-release__content__left__disclaimer",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".press-release__content__left__disclaimer",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Press release abouts
        identifier: ".press-release__content__right__items__item",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".press-release__content__right__items__item",
          autoplay: false,
          translateX: ["100vw", 0],
          delay: (_el, i, _l) => 150 * i,
        },
      },
    ]

    //Initialize animations
    init(targets)
  }, [])

  return (
    <div className="press-release">
      <div className="press-release__top">
        <Link
          to="/press-releases"
          className="press-release__top__back redirection-link"
          onClick={goBackCallback}
          id="press-releases__go-back"
          onMouseEnter={() => {
            execute({
              ...animeJSBaseParams,
              targets: "#press-releases__go-back",
              scale: [1, 1.1],
              translateX: [0, "5%"],
            })
          }}
          onMouseLeave={() => {
            execute({
              ...animeJSBaseParams,
              targets: "#press-releases__go-back",
              scale: [1.1, 1],
              translateX: ["5%", 0],
            })
          }}
          style={{
            color: goBackColor,
          }}
        >
          <ArrowIcon
            style={{
              transform: "rotate(180deg)",
            }}
            color={goBackColor}
          />
          Go back to Press Releases
        </Link>
        <h2
          className="press-release__top__title"
          style={{
            color: titleColor,
          }}
        >
          {release.title}
        </h2>
        <hr
          className="press-release__top__separator"
          style={{
            backgroundColor: separatorsColor,
          }}
        />
        <h4 className="press-release__top__subtitle">{release.subtitle}</h4>
      </div>
      <div className="press-release__content">
        <div className="press-release__content__left">
          <div
            className="press-release__content__left__text"
            dangerouslySetInnerHTML={{
              __html: release.content,
            }}
          />
          <hr
            className="press-release__content__left__separator"
            style={{
              backgroundColor: separatorsColor,
            }}
          />
          <div
            className="press-release__content__left__disclaimer"
            dangerouslySetInnerHTML={{
              __html: release.disclaimerText || defaultDisclaimerText,
            }}
          ></div>
        </div>
        <hr
          className="press-release__content__separator"
          style={{
            backgroundColor: separatorsColor,
          }}
        />
        <div className="press-release__content__right">
          <div className="press-release__content__right__items desktop">
            {release.abouts
              .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
              .map((about, i) => (
                <div
                  key={`press-release-${release.id}-about-${i}`}
                  className="press-release__content__right__items__item"
                >
                  <h6
                    className="press-release__content__right__items__item__title"
                    style={{
                      color: aboutsTitleColor,
                    }}
                  >
                    {about.title}
                  </h6>
                  <p className="press-release__content__right__items__item__text">
                    {about.content}
                  </p>
                </div>
              ))}
          </div>
          <div className="press-release__content__right__items mobile">
            {release.abouts
              .slice(
                mobileCurrentPage * mobilePageSize,
                mobileCurrentPage * mobilePageSize + mobilePageSize
              )
              .map((about, i) => (
                <div
                  key={`press-release-${release.id}-about-${i}`}
                  className="press-release__content__right__items__item"
                >
                  <h6
                    className="press-release__content__right__items__item__title"
                    style={{
                      color: aboutsTitleColor,
                    }}
                  >
                    {about.title}
                  </h6>
                  <p className="press-release__content__right__items__item__text">
                    {about.content}
                  </p>
                </div>
              ))}
          </div>
          {release.abouts.length > 2 ? (
            <div className="press-release__content__right__pagination desktop">
              <Pagination
                arrowless
                pages={Math.ceil(release.abouts.length / pageSize)}
                onClickHandler={setCurrentPage}
                color={aboutsPaginationColor}
              />
            </div>
          ) : (
            <></>
          )}
          {release.abouts.length > 1 ? (
            <div className="press-release__content__right__pagination mobile">
              <Pagination
                arrowless
                pages={Math.ceil(release.abouts.length / mobilePageSize)}
                onClickHandler={setMobileCurrentPage}
                color={aboutsPaginationColor}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className="press-release__content__disclaimer__mobile"
          dangerouslySetInnerHTML={{
            __html: release.disclaimerText || defaultDisclaimerText,
          }}
        ></div>
      </div>
    </div>
  )
}

export default PressReleaseDetails
