//Assets
import logo from "../../assets/nexwell-power-logo.svg";
//Styles
import "./spinner-layout.component.scss";

const SpinnerLayout = () => {
  return (
    <div className="spinner__layout">
      <img className="spinner__layout__logo" src={logo} alt="Nexwell Logo" />
    </div>
  );
};

export default SpinnerLayout;
