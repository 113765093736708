/**Special characters dict */
const from =
  "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";

/**Special characters translation dict */
const to =
  "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";

export const slugifyString = (s: string): string => {
  s = s
    .replaceAll(/^\s+|\s+$/g, "") //Replace special ASCII attributes
    .toLocaleLowerCase(); //Lower case

  for (let i = 0; i < from.length; i++) {
    //Search for special characters to replace
    s = s.replaceAll(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  //Remove invalid characters
  s = s
    .replaceAll(/[^a-z0-9 -]/g, "") //Remove invalid characters
    .replaceAll(/\s+/g, "-") //Collapse whitespaces with dashed
    .replaceAll(/-+/g, "-"); //Collapse dashes

  return s;
};
