//React imports
import { useCallback, useEffect, useRef, useState } from "react"
//Local imports
import { carouselAutoplay } from "../../config/animejs.config"
import { MediaObject } from "../../interfaces/shared"
import OverlayMedia from "../media/overlay-media.component"
import Pagination from "../pagination/pagination.component"
//Styles
import "./media-carousel.component.scss"

//Definitions
interface ImageCarouselProps {
  /**Media items for the carousel */
  mediaItems: MediaObject[]

  /**Autoplay in ms */
  autoplay?: number

  /**Container class name */
  containerClassName: string

  /**Media class name */
  mediaClassName: string
}

//Component
const MediaCarousel = ({
  mediaItems,
  autoplay = carouselAutoplay,
  containerClassName,
  mediaClassName,
}: ImageCarouselProps) => {
  //Refs
  /**Auto play ref */
  const autoplayRef = useRef<() => void>()
  const autoplayCallbackRef = useCallback(
    (node: () => void): void => { autoplayRef.current = node },
    [],
  )

  //State
  //Current slide
  const [currentSlide, setCurrentSlide] = useState(0)

  //Interval reference
  const [intervalReference, setIntervalReference] =
    useState<NodeJS.Timeout | null>(null)

  //Interval stopper
  const [autoplayStop, setAutoplayStop] = useState(false)

  //Methods
  /**Get next slide */
  const nextSlide = () => {
    if (!autoplayStop) {
      //Go no next slide
      if (currentSlide === mediaItems.length - 1) {
        //Prevent last overflow
        setCurrentSlide(0)
      } else {
        //Go to next
        setCurrentSlide(currentSlide + 1)
      }
    }
  }

  /**Play function wrapper */
  const play = () => {
    //If current ref exists
    if (autoplayRef.current)
      autoplayRef.current()
  }

  //Setup interval
  useEffect(() => {
    /**Interval for autoplay */
    const interval = setInterval(play, autoplay)

    //Update reference in state
    setIntervalReference(interval)

    //Unmount cleanup
    return () => {
      if (intervalReference) {
        //Clean interval reference on unmount
        clearInterval(intervalReference)
      }
    };
  }, [])

  //On each render, update the autoplay reference only if not stopped
  useEffect(() => {
    autoplayCallbackRef(nextSlide)
  })

  return (
    <div className="media-carousel">
      <div className="media-carousel__items">
        {mediaItems.map((media, i) => (
          <div
            id={`media-carousel-item-${i}`}
            key={`media-carousel-${media.type}-${i}`}
            className="media-carousel__items__item"
            style={{
              opacity: currentSlide === i ? 1 : 0,
              transition: "500ms",
              transform: `translateX(${currentSlide === i ? 0 : "50%"})`,
              pointerEvents: currentSlide === i ? 'all' : 'none',
            }}
          >
            <OverlayMedia
              containerClassName={containerClassName}
              mediaClassName={mediaClassName}
              media={media}
              imageAlt={`Carousel item #${i + 1}`}
              onMediaClick={media.type !== 'image' ? () => {
                // Clear autoplay to focus on video
                console.log("Clicked video, focus on it")

                // Stop autoplay
                setAutoplayStop(true)

                // Clear interval and stop autoplay
                if (!!intervalReference) {
                  clearInterval(intervalReference)
                  setIntervalReference(null)
                  autoplayCallbackRef(() => {})
                }
              } : undefined}
            />
          </div>
        ))}
      </div>
      <div className="media-carousel__pagination">
        <Pagination
          defaultPage={currentSlide}
          pages={mediaItems.length}
          onClickHandler={(i) => {
            //Update current slide
            setCurrentSlide(i)
            setAutoplayStop(false)

            //If the interval reference exists
            if (intervalReference) {
              //Clear previous interval reference
              clearInterval(intervalReference)

              /**New interval for autoplay */
              const interval = setInterval(play, autoplay)

              //Update reference in state
              setIntervalReference(interval)
            }
          }}
        />
      </div>
    </div>
  );
};

export default MediaCarousel;
