import { useContext, useEffect, useState } from "react"
import Banner from "../../../components/banner/banner.component"
import OverlayMedia from "../../../components/media/overlay-media.component"
import SEOHead from "../../../components/seo-head/seo-head.component"
import { animeJSBaseParams } from "../../../config/animejs.config"
import { AnalyticsContext } from "../../../context/analytics.context"
import { PreloadContext } from "../../../context/preload.context"
import useViewAnimations, { AnimationTarget } from "../../../hooks/animations.hook"
import { ESGEnvironmentalDoc } from "../../../interfaces/esg/environmental"
import "./esg-environmental.view.scss"

const ESGEnvironmental = () => {
  //Contexts
  //Preload context
  const { getViewData } = useContext(PreloadContext)

  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext)

  //Hooks
  //Animation hook
  const { init, execute } = useViewAnimations()

  //State
  //View contents
  const [content, setContent] = useState<ESGEnvironmentalDoc | undefined>(
    getViewData("ESGEnvironmental") as ESGEnvironmentalDoc
  )

  //Emit page view event
  useEffect(() => {
    if (content && !pageViewSent) {
      //Home data fetched and page view not sent yet
      emitPageView(content.seo?.title || "Nexwell Power | ESG Environmental")

      //Update flag
      setPageViewSent(true)
    }
    // eslint-disable-next-line
  }, [content])

  //On contents change
  useEffect(() => {
    //Update content in state
    setContent(getViewData("ESGEnvironmental") as ESGEnvironmentalDoc)

    // eslint-disable-next-line
  }, [getViewData("ESGEnvironmental")])

  //On first load
  useEffect(() => {
    //On browser
    if (typeof window !== "undefined")
      window.scrollTo({ top: 0, behavior: "smooth" }) //Scroll to top

    /**Animations targets */
    const targets: AnimationTarget[] = [
      {
        //Highlight image flip in from left
        identifier: ".esg-environmental__content__highlight",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".esg-environmental__content__highlight",
          autoplay: false,
          rotateZ: ["12deg", "0deg"],
          rotateY: ["-90deg", "0deg"],
          duration: 700,
        },
      },
      {
        //Section 2 title
        identifier: ".esg-environmental__content__texts",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".esg-environmental__content__texts",
          autoplay: false,
          translateX: ["100vw", 0],
        },
      },
    ]

    //Animations initialization
    init(targets)
  }, [])

  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false)

  return (
    <main id="esg-environmental__view">
      {content?.seo ? <SEOHead {...content.seo} /> : <></>}
      <Banner
        textureUrl={content?.banner.textureUrl}
        background={{
          color: content?.banner.backgroundColor ?? "#029839",
        }}
        separator={{
          color: content?.banner.separatorColor ?? "#fff",
        }}
        ringImageUrl={content?.banner.ringImageUrl!}
        title={
          <div
            className="esg-environmental-banner-title-payload"
            dangerouslySetInnerHTML={{
              __html: content?.banner.title ?? "",
            }}
          ></div>
        }
        specificClassNames="esg-environmental"
        subtitle={
          <div
            dangerouslySetInnerHTML={{
              __html: content?.banner.subtitle ?? "",
            }}
          ></div>
        }
      />
      <section
        className="esg-environmental__main"
        style={{
          backgroundColor: content?.mainSection.backgroundColor,
        }}
      >
        <div className="esg-environmental__content__hideable-texts">
          {content?.mainSection.introTitle?.hide === false && (
            <div
              className="esg-environmental__content__hideable-texts__title"
              dangerouslySetInnerHTML={{
                __html: content?.mainSection.introTitle?.content ?? '',
              }}
            />
          )}
          {!!content?.mainSection.introText?.hide === false && (
            <div
              className="esg-environmental__content__hideable-texts__text"
              dangerouslySetInnerHTML={{
                __html: content?.mainSection.introText?.content ?? '',
              }}
            />
          )}
        </div>
        <div className="esg-environmental__content">
          <div className="esg-environmental__content__highlight">
            <OverlayMedia
              media={
                content?.mainSection.image ?? {
                  type: "image",
                  url: "",
                  overlayAlpha: 0,
                }
              }
              containerClassName="esg-environmental__content__highlight__image-container"
              mediaClassName="esg-environmental__content__highlight__image-container__media"
              imageAlt="ESG Environmental"
            />
            <div
              className="esg-environmental__content__highlight__text"
              dangerouslySetInnerHTML={{
                __html: content?.mainSection.title ?? "",
              }}
            />
            <hr className="esg-environmental__content__highlight__separator" />
          </div>
          <div className="esg-environmental__content__texts">
            <div
              className="esg-environmental__content__texts__top"
              dangerouslySetInnerHTML={{
                __html: content?.mainSection.topText ?? "",
              }}
            />
            <hr
              className="esg-environmental__content__texts__separator"
              style={{
                backgroundColor: content?.mainSection.separatorColor,
              }}
            />
            <div
              className="esg-environmental__content__texts__bottom"
              dangerouslySetInnerHTML={{
                __html: content?.mainSection.bottomText ?? "",
              }}
            />
          </div>
        </div>
        <div className="esg-environmental__content__hideable-texts">
          {!!content?.mainSection.outroTitle?.hide === false && (
            <div
              className="esg-environmental__content__hideable-texts__title"
              dangerouslySetInnerHTML={{
                __html: content?.mainSection.outroTitle?.content ?? '',
              }}
            />
          )}
          {!!content?.mainSection.outroText?.hide === false && (
            <div
              className="esg-environmental__content__hideable-texts__text"
              dangerouslySetInnerHTML={{
                __html: content?.mainSection.outroText?.content ?? '',
              }}
            />
          )}
        </div>
      </section>
    </main>
  )
}

export default ESGEnvironmental