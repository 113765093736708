import { ChangeEventHandler, useEffect, useState } from 'react'
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist"
import { ReactComponent as MenuBurger } from "../../assets/icons/menu_burger.svg"
import { ReactComponent as Download } from "../../assets/icons/download.svg"
import "./pdf-viewer.component.scss"
import { Metadata } from "pdfjs-dist/types/src/display/metadata"
import PdfViewerPagePreview from './pdf-viewer-page-preview.component'
import PdfViewerPage from './pdf-viewer-page.component'

const PdfViewer = ({
  pdfUrl,
  pdfTitle,
  onLoad,
  onError,
}: {
  pdfUrl: string
  pdfTitle?: string
  onLoad?: () => void
  onError?: () => void
}) => {
  //PDF Data
  const [pdfRef, setPdfRef] = useState<PDFDocumentProxy>()
  const [pdfMetadata, setPdfMetadata] = useState<{
    info: Object
    metadata: Metadata
  }>()

  //Pages handling
  const [pages, setPages] = useState<PDFPageProxy[]>([])
  const [currentPage, setCurrentPage] = useState(1)

  //Scrolls the content of the pdf to the given page by it's id
  const scrollToPage = (pageId: string) => {
    const page = document.getElementById(pageId)

    if (page)
      page.scrollIntoView()
  }

  //Handle input page change then scroll to page selected
  const handlePageChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const page = parseInt(e.target.value)

    if (isNaN(page)) return setCurrentPage(1)

    setCurrentPage(
      page > (pdfRef?.numPages ?? 1)
        ? pdfRef?.numPages ?? 1
        : page < 1
        ? 1
        : page
    )
  }

  //Zoom handling
  const [zoomIndex, setZoomIndex] = useState(
    window.innerWidth <= 768
      ? 4
      : window.innerWidth <= 1024
      ? 8
      : 10
  )
  const zoomSteps = [
    0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1.0, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3,
    4, 5,
  ]

  //Moves inside the zoom steps and restores custom zoom if needed
  const moveZoomStep = (step: -1 | 1) => {
    const newIndex = zoomIndex + step;

    //Out of bounds
    if (newIndex < 0 || newIndex >= zoomSteps.length) {
      return
    }

    setZoomIndex(newIndex)
  }

  //Sidebar handling
  const [showSide, setShowSide] = useState(true)
  const toggleSideBar = () => setShowSide(!showSide)

  //On page changes
  useEffect(() => {
    scrollToPage(`pdf-${pdfUrl}-page-content-${currentPage}`)
  }, [currentPage])

  //Load pdf
  useEffect(() => {
    ;(async function () {
      try {
        // We import this here so that it's only loaded during client-side rendering.
        const pdfJS = await import("pdfjs-dist/legacy/build/pdf")
        pdfJS.GlobalWorkerOptions.workerSrc =
          window.location.origin + "/pdf.worker.min.js"

        //Load pdf
        const pdf = await pdfJS.getDocument(pdfUrl).promise

        //Load pdf metadata
        const metadata = await pdf.getMetadata()

        //Update state
        setPdfRef(pdf)

        setPdfMetadata(metadata)

        //Load pdf pages
        setPages(
          await Promise.all(
            Array(pdf.numPages)
              .fill(null)
              .map(async (_, i) => await pdf.getPage(i + 1))
          )
        )

        onLoad && onLoad()
      } catch (err) {
        console.log(err)
        onError && onError()
      }
    })()
  }, [])

  return (
    <div className="pdf-viewer__container">
      <div className="pdf-viewer__container__toolbar">
        <div className="pdf-viewer__container__toolbar__left">
          <div
            onClick={toggleSideBar}
            className="pdf-viewer__container__toolbar__left__burger"
          >
            <MenuBurger />
          </div>
          <h2>{(pdfMetadata?.info as any)?.title ?? pdfTitle ?? "Título"}</h2>
        </div>
        <div className="pdf-viewer__container__toolbar__center">
          <div className="pdf-viewer__container__toolbar__center__pages">
            <span className="pdf-viewer__container__toolbar__center__pages__current">
              <input
                type="number"
                value={currentPage}
                onChange={handlePageChange}
                step="1"
                min="1"
                max={pdfRef?.numPages ?? 1}
              />
            </span>
            <span className="pdf-viewer__container__toolbar__center__pages__divider">
              /
            </span>
            <span className="pdf-viewer__container__toolbar__center__pages__all">
              {pdfRef?.numPages ?? 1}
            </span>
          </div>
          <div className="pdf-viewer__container__toolbar__center__vertical-separator"></div>
          <div className="pdf-viewer__container__toolbar__center__zoom">
            <span
              onClick={() => moveZoomStep(-1)}
              className={`pdf-viewer__container__toolbar__center__zoom__button ${
                zoomIndex <= 0 ? "disabled" : ""
              }`}
            >
              -
            </span>
            <span className="pdf-viewer__container__toolbar__center__zoom__amount">
              <input
                type="text"
                value={`${(zoomSteps[zoomIndex] * 100).toFixed(0)}%`}
                readOnly
              />
            </span>
            <span
              onClick={() => moveZoomStep(1)}
              className={`pdf-viewer__container__toolbar__center__zoom__button ${
                zoomIndex >= zoomSteps.length - 1 ? "disabled" : ""
              }`}
            >
              +
            </span>
          </div>
        </div>
        <div className="pdf-viewer__container__toolbar__right">
          <div className="pdf-viewer__container__toolbar__right__download">
            <a href={pdfUrl} target="_blank" download>
              <Download />
            </a>
          </div>
        </div>
      </div>
      <div className="pdf-viewer__container__main">
        <div
          className={`pdf-viewer__container__main__side ${
            showSide ? "" : "hide"
          }`}
        >
          {pages.map((page) => (
            <PdfViewerPagePreview
              page={page}
              currentPage={currentPage === page.pageNumber}
              onClickCallback={() => setCurrentPage(page.pageNumber)}
              key={`pdf-${pdfUrl}-page-preview-${page.pageNumber}`}
            />
          ))}
        </div>
        <div
          className={`pdf-viewer__container__main__content ${
            showSide ? "" : "full"
          }`}
        >
          {pages.map((page) => (
            <PdfViewerPage
              page={page}
              zoom={zoomSteps[zoomIndex]}
              pageId={`pdf-${pdfUrl}-page-content-${page.pageNumber}`}
              key={`pdf-${pdfUrl}-page-content-${page.pageNumber}`}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default PdfViewer