//Third parties
import { Helmet } from "react-helmet-async";
//Local imports
import { SEODataView } from "../../interfaces/seo";

/**SEO head component */
const SEOHead = ({
  description,
  keywords,
  og,
  title,
  twitter,
}: SEODataView) => {
  //Data
  /**Website url */
  const url = window
    ? `${window.location.protocol}//${window.location.host}`
    : "https://nexwellpower.com";

  /**Og fallback image */
  const ogFallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/nexwell-dev.appspot.com/o/Power2021%2FBrand%2Fog-image-min.png?alt=media&token=e5918ee3-12b9-4d40-bb09-9c8db89607f0";

  /**Twitter fallback image */
  const twitterFallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/nexwell-dev.appspot.com/o/Power2021%2FBrand%2Ftwitter-image-min.png?alt=media&token=73eba3fb-a238-432b-b518-482b4c162fda";

  return (
    <Helmet>
      {/**Basic SEO title */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/**Open graph SEO tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={og.title || title} />
      <meta property="og:description" content={og.description || description} />
      {/* <meta property="og:image" content={og.image || ogFallbackImage} />
      <meta
        property="og:image:secure_url"
        content={og.image || ogFallbackImage}
      /> */}

      {/**Twitter SEO tags */}
      <meta property="twitter:type" content="website" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={twitter.title || title} />
      <meta
        property="twitter:description"
        content={twitter.description || description}
      />
      {/* <meta
        property="twitter:image"
        content={twitter.image || twitterFallbackImage}
      /> */}
    </Helmet>
  );
};

export default SEOHead;
