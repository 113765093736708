//Firebase imports
import "firebase/app";
import "firebase/firestore";

/**Firebase config */
export const firebaseClientConfig = {
  apiKey: "AIzaSyBZG-Zrp8diGruznicYTHvBf4HIdMTiqy4",
  authDomain: "nexwell-dev.firebaseapp.com",
  databaseURL: "https://nexwell-dev.firebaseio.com",
  projectId: "nexwell-dev",
  storageBucket: "nexwell-dev.appspot.com",
  messagingSenderId: "732335184299",
  appId: "1:732335184299:web:ecbca4e85a308c45f49aee",
  measurementId: "G-WSER8HQ4Q4",
};
