import { useContext, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import Banner from "../../../components/banner/banner.component"
import PdfViewer from "../../../components/pdf-viewer/pdf-viewer.component"
import SEOHead from "../../../components/seo-head/seo-head.component"
import SpinnerLayout from "../../../components/spinner-layout/spinner-layout.component"
import { animeJSBaseParams } from "../../../config/animejs.config"
import { AnalyticsContext } from "../../../context/analytics.context"
import { PreloadContext } from "../../../context/preload.context"
import useViewAnimations, { AnimationTarget } from "../../../hooks/animations.hook"
import { ESGGovernanceDoc } from "../../../interfaces/esg/governance"
import { slugifyString } from "../../../utils/strings.utils"
import './esg-governance.view.scss';

const ESGGovernance = () => {
  //Contexts
  //Preload context
  const { getViewData } = useContext(PreloadContext)

  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext)

  //Hooks
  //Animation hook
  const { init, execute } = useViewAnimations()

  /**Query params with locations hook */
  let query = new URLSearchParams(useLocation().search)
  const policyQuery = query.get("policy")

  //State
  //View contents
  const [content, setContent] = useState<ESGGovernanceDoc | undefined>(
    getViewData("ESGGovernance") as ESGGovernanceDoc
  )

  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false)

  //PDF Load flags
  const pdfShouldLoad = !!policyQuery
  const [pdfLoaded, setPdfLoaded] = useState(false)

  //Emit page view event
  useEffect(() => {
    if (content && !pageViewSent) {
      //Home data fetched and page view not sent yet
      emitPageView(content.seo?.title || "Nexwell Power | ESG Governance")

      //Update flag
      setPageViewSent(true)
    }
    // eslint-disable-next-line
  }, [content])

  //On contents change
  useEffect(() => {
    //Update content in state
    setContent(getViewData("ESGGovernance") as ESGGovernanceDoc)

    // eslint-disable-next-line
  }, [getViewData("ESGGovernance")])

  //On first load
  useEffect(() => {
    //On browser
    if (typeof window !== "undefined")
      window.scrollTo({ top: 0, behavior: "smooth" }) //Scroll to top

    /**Animations targets */
    const targets: AnimationTarget[] = [
      {
        //Policies list title
        identifier: ".esg-governance__policies-list__title",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".esg-governance__policies-list__title",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Policies list items
        identifier: ".esg-governance__policies-list__items__item",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".esg-governance__policies-list__items__item",
          autoplay: false,
          translateX: ["-100vw", 0],
          delay: (_el, i, _l) => 100 * i,
        },
      },
    ]

    //Animations initialization
    init(targets)
  }, [])

  return (
    <main className="esg-governance__view">
      {pdfShouldLoad && !pdfLoaded && <SpinnerLayout />}
      {content?.seo ? <SEOHead {...content.seo} /> : <></>}
      <Banner
        textureUrl={content?.banner.textureUrl}
        background={{
          color: content?.banner.bgColor || "#029839",
        }}
        separator={{
          color: "#fff",
        }}
        ringImageUrl={content?.banner.ringImageUrl!}
        title={
          <div
            className="esg-governance-banner-title-payload"
            dangerouslySetInnerHTML={{
              __html: content?.banner.title as string,
            }}
          ></div>
        }
        specificClassNames="esg-governance"
        subtitle={
          <div
            dangerouslySetInnerHTML={{
              __html: content?.banner.subtitle as string,
            }}
          ></div>
        }
      />
      <section
        className="esg-governance__policies-list"
        style={{
          backgroundColor: content?.policiesList.backgroundColor,
        }}
      >
        <div className="esg-governance__policies-list__hideable-texts">
          {content?.policiesList.topTitle?.hide === false && (
            <div
              className="esg-governance__policies-list__hideable-texts__title"
              dangerouslySetInnerHTML={{
                __html: content?.policiesList.topTitle?.content ?? "",
              }}
            />
          )}
          {!!content?.policiesList.topText?.hide === false && (
            <div
              className="esg-governance__policies-list__hideable-texts__text"
              dangerouslySetInnerHTML={{
                __html: content?.policiesList.topText?.content ?? "",
              }}
            />
          )}
        </div>
        <div
          className="esg-governance__policies-list__title"
          dangerouslySetInnerHTML={{
            __html: content?.policiesList.title ?? "",
          }}
        ></div>
        <ul className="esg-governance__policies-list__items">
          {content?.policiesList.policies.map(({ name, url }, i) => (
            <li
              key={"esg-governance__policies-list__pdf--" + i}
              className="esg-governance__policies-list__items__item"
            >
              <Link
                id={"esg-governance__policies-list__pdf--" + i}
                className="esg-governance__policies-list__items__item--link"
                to={`/esg/governance?policy=${slugifyString(name)}`}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={() => {
                  execute({
                    ...animeJSBaseParams,
                    targets: "#esg-governance__policies-list__pdf--" + i,
                    translateX: [0, "2.5%"],
                    scale: [1, 1.05],
                    opacity: [1, 0.7],
                  })
                }}
                onMouseLeave={() => {
                  execute({
                    ...animeJSBaseParams,
                    targets: "#esg-governance__policies-list__pdf--" + i,
                    translateX: ["2.5%", 0],
                    scale: [1.05, 1],
                    opacity: [0.7, 1],
                  })
                }}
              >
                <img
                  className="esg-governance__policies-list__items__item__icon"
                  alt="PDF"
                  src={content?.policiesList.itemIconUrl}
                />
                <p
                  className="esg-governance__policies-list__items__item__name"
                  style={{
                    color: content?.policiesList.itemTextColor,
                  }}
                >
                  {name}
                </p>
              </Link>
            </li>
          ))}
        </ul>
      </section>
      {pdfShouldLoad && (
        <div className="esg-governance__pdf">
          <PdfViewer
            pdfUrl={
              content?.policiesList.policies.find(
                ({ name }) => slugifyString(name) === policyQuery
              )?.url ?? ""
            }
            pdfTitle={
              content?.policiesList.policies.find(
                ({ name }) => slugifyString(name) === policyQuery
              )?.name
            }
            onLoad={() => {
              setPdfLoaded(true)
            }}
            onError={() => {
              setPdfLoaded(true)
            }}
          />
          {/* <object
            id="pdf-viewer"
            data={
              content?.policiesList.policies.find(
                ({ name }) => slugifyString(name) === policyQuery
              )?.url ?? ""
            }
            type="application/pdf"
            onLoad={() => {
              setPdfLoaded(true)
            }}
            onError={() => {
              setPdfLoaded(true)
            }}
          >
            <div>No online PDF viewer installed</div>
            <a
              href={
                content?.policiesList.policies.find(
                  ({ name }) => slugifyString(name) === policyQuery
                )?.url ?? ""
              }
              target="_blank"
              download
            >
              Download PDF
            </a>
          </object> */}
        </div>
      )}
    </main>
  )
}

export default ESGGovernance;