//React imports
import { useEffect, useState } from "react";
//Third parties imports
import anime, { AnimeInstance, AnimeParams } from "animejs";

export interface AnimationTarget {
  identifier: string;
  animationParams: AnimeParams;
  viewOffset?: number[];
  midPointOffset?: number;
  done?: boolean;
}

const useViewAnimations = () => {
  const [animationTargets, setAnimationTargets] = useState<
    AnimationTarget[] | null
  >(null);

  function elementIsVisible(
    element: Element | null,
    viewOffset: number[] = [],
    midPointOffset?: number
  ): boolean {
    // eslint-disable-next-line
    const [_ = 0, offsetY = 0] = viewOffset;

    if (!element) return false;

    /**Element rendered rect on client */
    const rect = element.getBoundingClientRect();

    /**Height of the view */
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );

    /**Return item is inside from below or from the top */
    return midPointOffset !== undefined
      ? !(
          (rect.bottom + rect.top) / 2 +
            (rect.bottom - rect.top) * midPointOffset -
            offsetY <
            0 ||
          (rect.bottom + rect.top) / 2 +
            (rect.bottom - rect.top) * midPointOffset -
            offsetY -
            viewHeight >=
            0
        )
      : !(rect.bottom - offsetY < 0 || rect.top - offsetY - viewHeight >= 0);
  }

  const init = (targets: AnimationTarget[]) => setAnimationTargets(targets);

  /**
   * Executes the given animation with the given params
   * @param {anime.AnimeParams} params Parameters for animation
   */
  const execute = (params: anime.AnimeParams) => {
    anime(params);
  };

  useEffect(() => {
    if (!animationTargets) return;

    const elements: (Element | null)[] = [];
    const animeInstances: AnimeInstance[] = [];

    animationTargets.forEach((target) => {
      elements.push(document.querySelector(target.identifier));
      animeInstances.push(anime(target.animationParams));
    });

    function handleScroll() {
      animationTargets!.forEach((target, i) => {
        if (
          elementIsVisible(
            elements[i]!,
            target.viewOffset,
            target.midPointOffset
          ) &&
          !target.done
        ) {
          animeInstances[i].play();
          target.done = true;
        }
      });
    }

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [animationTargets]);

  return {
    init,
    execute,
  };
};

export default useViewAnimations;
