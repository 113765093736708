//Third parties
import { useFirestore, useFirestoreCollectionData } from "reactfire";
//Local imports
import { PressRelease } from "../interfaces/press-releases";
import { getEnvironment } from "../utils/environment.utils";

const usePressReleases = (
  limit?: number
): {
  get: () => PressRelease[];
} => {
  /**Firestore reference */
  const firestore = useFirestore();

  /**Press releases query ref */
  let pressReleasesQuery = firestore
    .collection("NexwellPower2021") //Get from
    .doc(getEnvironment()) //Corresponding environment
    .collection("News") //The press releases
    .where("deleted", "==", false) //That aren't deleted
    .where("active", "==", true) //And are active
    .orderBy("releaseDate", "desc"); //Ordered from most recent to latest

  const { data } = useFirestoreCollectionData<PressRelease>(
    limit && limit > 0 ? pressReleasesQuery.limit(limit) : pressReleasesQuery,
    {
      initialData: [],
      idField: "id",
    }
  );

  const get = (): PressRelease[] => data;

  return {
    get,
  };
};

export default usePressReleases;
