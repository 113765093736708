import { useContext, useEffect, useState } from "react";
import { PreloadContext } from "../../context/preload.context";
import { ESGDocV2 } from "../../interfaces/esg";

import "./esg.view.scss";
import useViewAnimations, {
  AnimationTarget,
} from "./../../hooks/animations.hook";
import Banner from "../../components/banner/banner.component";
import { Link } from "react-router-dom";
import ArrowIcon from "../../components/arrow/arrow.component";
import { animeJSBaseParams } from "../../config/animejs.config";
import { AnalyticsContext } from "../../context/analytics.context";
import SEOHead from "../../components/seo-head/seo-head.component";
import OverlayMedia from "../../components/media/overlay-media.component";

const ESG = () => {
  const targets: AnimationTarget[] = [
    {
      //Areas description
      identifier: ".esg-view__areas__description",
      animationParams: {
        ...animeJSBaseParams,
        targets: ".esg-view__areas__description",
        autoplay: false,
        translateX: ["-100vw", 0],
      },
    },
    {
      //Areas title
      identifier: ".esg-view__areas__title",
      animationParams: {
        ...animeJSBaseParams,
        targets: ".esg-view__areas__title",
        autoplay: false,
        translateX: ["-100vw", 0],
      },
    },
    {
      //Areas list
      identifier: ".esg-view__areas__list__item",
      animationParams: {
        ...animeJSBaseParams,
        targets: ".esg-view__areas__list__item",
        autoplay: false,
        translateX: ["-100vw", 0],
        delay: (_el, i, _l) => 100 * i,
      },
    },
  ]

  const viewAnimations = useViewAnimations();

  useEffect(() => {
    //On browser
    if (typeof window !== "undefined")
      window.scrollTo({ top: 0, behavior: "smooth" }) //Scroll to top

    viewAnimations.init(targets)
  }, []);

  const [esgData, setEsgData] = useState<ESGDocV2 | undefined>()
  const { getViewData, isReadyToLoad } = useContext(PreloadContext);

  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext);

  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false);

  //Emit page view event
  useEffect(() => {
    if (esgData && !pageViewSent) {
      //Home data fetched and page view not sent yet
      emitPageView(esgData.seo?.title || "Nexwell Power | ESG");

      //Update flag
      setPageViewSent(true);
    }
    // eslint-disable-next-line
  }, [esgData]);

  useEffect(() => {
    if (isReadyToLoad) {
      setEsgData(getViewData("ESGV2") as ESGDocV2)
    }
  }, [isReadyToLoad, getViewData("ESGV2")])

  const { banner, areasSection } = esgData || {};

  return esgData ? (
    <main className="esg-view">
      {esgData?.seo ? <SEOHead {...esgData.seo} /> : <></>}
      <Banner
        textureUrl={banner?.textureUrl}
        background={{
          color: banner?.backgroundColor ?? "#fff",
        }}
        separator={{
          color: banner?.separatorColor ?? "#fff",
        }}
        ringImageUrl={banner!.ringImageUrl!}
        title={
          <p
            className="esg-banner-title-payload"
            dangerouslySetInnerHTML={{ __html: banner?.title ?? "" }}
          ></p>
        }
        specificClassNames="esg"
        subtitle={
          <>
            <p
              dangerouslySetInnerHTML={{
                __html: banner?.subtitle ?? "",
              }}
            ></p>
          </>
        }
      />

      <section
        className="esg-view__areas"
        style={{
          backgroundColor: areasSection?.backgroundColor,
        }}
      >
        <div
          className="esg-view__areas__description"
          dangerouslySetInnerHTML={{
            __html: areasSection?.description ?? "",
          }}
        ></div>
        <div
          className="esg-view__areas__title"
          dangerouslySetInnerHTML={{
            __html: areasSection?.title ?? "",
          }}
        ></div>
        <ul className="esg-view__areas__list">
          {areasSection?.areas.map(
            ({ imageUrl, name, overlayAlpha, redirectionUrl, text }, i) => {
              return (
                <li className="esg-view__areas__list__item">
                  <p
                    className="esg-view__areas__list__item--name"
                    style={{
                      color: areasSection?.itemTextColor,
                    }}
                  >
                    {name}
                  </p>
                  {text && (
                    <p
                      className="esg-view__areas__list__item--text"
                      style={{
                        color: areasSection?.itemTextColor,
                      }}
                    >
                      {text}
                    </p>
                  )}
                  <hr
                    className="esg-view__areas__list__item--separator"
                    style={{
                      backgroundColor: areasSection.itemSeparatorColor,
                    }}
                  />
                  <Link
                    to={redirectionUrl}
                    className="esg-view__areas__list__item--redirect"
                    style={{
                      color: areasSection?.itemRedirectionColor,
                    }}
                    id={`esg-view__areas__list__item--redirect--${i}`}
                    onMouseEnter={() => {
                      viewAnimations.execute({
                        ...animeJSBaseParams,
                        targets: `#esg-view__areas__list__item--redirect--${i}`,
                        translateX: [0, "5%"],
                        scale: [1, 1.1],
                        opacity: [1, 0.7],
                      })
                    }}
                    onMouseLeave={() => {
                      viewAnimations.execute({
                        ...animeJSBaseParams,
                        targets: `#esg-view__areas__list__item--redirect--${i}`,
                        translateX: ["5%", 0],
                        scale: [1.1, 1],
                        opacity: [0.7, 1],
                      })
                    }}
                  >
                    Know More{" "}
                    <ArrowIcon color={areasSection?.itemRedirectionColor} />
                  </Link>
                  <OverlayMedia
                    media={{
                      type: "image",
                      url: imageUrl,
                      overlayAlpha,
                    }}
                    containerClassName="esg-view__areas__list__item--image-container"
                    imageAlt={name}
                    mediaClassName="esg-view__areas__list__item--image-container__media"
                  />
                </li>
              )
            }
          )}
        </ul>
      </section>
    </main>
  ) : null
};

export default ESG;
