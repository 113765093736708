//React imports
import { useContext } from "react";
//Third parties
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//Local imports
import { routes } from "./routes/index";
import Header from "./components/header/header.component";
import { PreloadContext } from "./context/preload.context";
import SpinnerLayout from "./components/spinner-layout/spinner-layout.component";
//Styles
import "./App.scss";
import Footer from "./components/footer/footer.component";

function App() {
  //Context
  //Preload
  const { isReadyToLoad } = useContext(PreloadContext);

  return isReadyToLoad ? (
    <Router>
      <Header />
      <Switch>
        {routes.map((route, i) => (
          <Route {...route} key={`public-routes-route-${i}`} />
        ))}
      </Switch>
      <Footer />
    </Router>
  ) : (
    <SpinnerLayout />
  );
}

export default App;
