//Styles
import "./overlay-image.component.scss";

//Definitions
/**Image with overlay props */
interface OverlayImageProps {
  /**Image src */
  src: string;

  /**Overlay alpha */
  overlayAlpha?: number;

  /**Image class name */
  imageClassName?: string;

  /**Container class name */
  containerClassName?: string;

  /**Image alt */
  imageAlt?: string;
}

/**
 * @deprecated Use Overlay Media component for better options
 */
const OverlayImage = ({
  src,
  containerClassName,
  imageClassName,
  overlayAlpha,
  imageAlt,
}: OverlayImageProps) => {
  return (
    <div
      className={`image__container${
        containerClassName ? ` ${containerClassName}` : ""
      }`}
    >
      <div
        className="image__container__overlay"
        style={{
          opacity:
            overlayAlpha && overlayAlpha > 0
              ? overlayAlpha < 1
                ? overlayAlpha
                : 1
              : 0,
        }}
      ></div>
      <img src={src} alt={imageAlt} className={imageClassName} />
    </div>
  );
};

export default OverlayImage;
