/**
 * Get an overlay for styles
 * @param {number} alpha
 * @returns {string} Background property value
 */
export const getOverlayBackground = (alpha?: number): string => {
  return `linear-gradient(0deg, rgba(0,0,0,${
    alpha && alpha > 0 ? (alpha < 1 ? alpha : 1) : 0
  }), rgba(0,0,0,${alpha && alpha > 0 ? (alpha < 1 ? alpha : 1) : 0}))`;
};

/**
 * Get a background with overlay for styles
 * @param {string} imageUrl Background image
 * @param {number} alpha
 * @returns {string} Background property value
 */
export const getBackgroundWithOverlay = (
  imageUrl: string,
  alpha?: number
): string => {
  return `linear-gradient(0deg, rgba(0,0,0,${
    alpha && alpha > 0 ? (alpha < 1 ? alpha : 1) : 0
  }), rgba(0,0,0,${
    alpha && alpha > 0 ? (alpha < 1 ? alpha : 1) : 0
  })), url(${imageUrl})`;
};
