//React imports
import { useEffect, useState } from "react";
//Local imports
import { animeJSBaseParams } from "../../config/animejs.config";
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/animations.hook";
// import { getBackgroundWithOverlay } from "../../utils/overlay.utils";
//Styles
import "./banner.component.scss";
import useImagePreloader from './../../hooks/image-preloader.hook';

//Definitions
/**Banner component props */
interface BannerProps {
  /**Banner background */
  background: {
    /**Background color */
    color: string;

    /**Background Image */
    image?: string; //Image

    /**Background image overlay value */
    overlayAlpha?: number;
  };

  /**Banner ring */
  ringImageUrl: string;

  /**Separator */
  separator?: {
    /**Color for the separator */
    color?: string;

    /**Custom class name */
    className?: string;

    /**Custom styles */
    styles?: React.CSSProperties;
  };

  /**Banner title */
  title?: string | JSX.Element;

  /**Banner subtitle */
  subtitle?: string | JSX.Element;

  /**Bottom text for banner */
  bottomText?: string | JSX.Element;

  /** Specific class names for each view */
  specificClassNames?: string;

  /**Override for animations */
  targetsOverride?: AnimationTarget[];

  textureUrl?: string;
}

/**Banner component */
const Banner = ({
  title,
  separator,
  subtitle,
  background,
  bottomText,
  ringImageUrl,
  specificClassNames = "",
  targetsOverride,
  textureUrl,
}: BannerProps) => {
  //Hooks
  //Animations hook
  const { init } = useViewAnimations();

  const [ courtainHidden, setCourtainHidden ] = useState(false);
  const [ isSafariMobile, setIsSafariMobile ] = useState(false);
  const [ scrollAmount, setScrollAmount ] = useState(0);

  //Animation use effect
  useEffect(() => {
    /**Animation targets */
    const targets: AnimationTarget[] = [
      {
        identifier: ".banner__container__outer",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".banner__container__outer",
          opacity: [0, 1],
          duration: 1000,
        },
      },
      {
        identifier: ".banner__container__top",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".banner__container__top",
          translateX: ["-100vw", "0"],
          duration: 800,
        },
      },
      {
        identifier: ".banner__container__bottom",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".banner__container__bottom",
          translateY: ["100vh", "0"],
          duration: 800,
        },
        done: true,
      },
      {
        identifier: ".banner__container__rings",
        animationParams: {
          targets: ".banner__container__rings",
          translateX: [window.innerWidth, 0],
          opacity: [0, 1],
          ...animeJSBaseParams,
        },
      },
    ];

    //Initialize animations
    init(targetsOverride ?? targets);

    // eslint-disable-next-line
  }, []);

  const imagePreloader = useImagePreloader();
  const animator = useViewAnimations();
  useEffect(() => {
    imagePreloader.preload(textureUrl!)
      .then(() => {
        animator.execute({
          targets: ".courtain",
          opacity: [1, 0],
          ...animeJSBaseParams,
          complete: () => {
            setCourtainHidden(true);
          }
        });
      });
  }, []);

  useEffect(() => {

    let isSafari = false;
    const userAgent = navigator.userAgent.toLowerCase(); 
    if (userAgent.indexOf('safari') !== -1) { 
      if (!(userAgent.indexOf('chrome') > -1)) {
        isSafari = true;
      }
    }

    function handlePotentialScrollChange() {
      if (!isSafari) return;
      
      if (window.innerWidth <= 1024) {
        setIsSafariMobile(true);
        setScrollAmount(window.scrollY);
      } else {
        setIsSafariMobile(false);
      }
    }

    window.addEventListener("scroll", handlePotentialScrollChange);
    window.addEventListener("resize", handlePotentialScrollChange);
    
    handlePotentialScrollChange();
    
    return () => {
      window.removeEventListener("scroll", handlePotentialScrollChange);
      window.removeEventListener("resize", handlePotentialScrollChange);
    }
  }, []);

  return (
    <section
      className={`banner banner__container__outer ${specificClassNames}`}
      style={{
        //BG Image properties
        backgroundImage: `url(${textureUrl})`,
        backgroundAttachment: `${isSafariMobile ? 'unset' : 'fixed'}`,
        backgroundPosition: `right bottom ${-scrollAmount}px`

        // //BG Color properties
      }}
    >
      <div 
        className="mask-container"
        style={{
          backgroundColor: background.color,
        }}
      >
      </div>  

      <div 
        className={`courtain ${courtainHidden ? 'hidden' : ''}`}
        style={{
          backgroundColor: background.color,
        }}
      >

      </div>

      <div className="banner__container__top">
        {title !== undefined && (
          <div className="banner__container__top__title">
            {typeof title === "string" ? <h1>{title}</h1> : title}
          </div>
        )}

        {separator && (
          <hr
            className={`banner__container__top__separator${
              separator.className ? ` ${separator.className}` : ""
            }`}
            style={{
              color: separator.color,
              backgroundColor: separator.color,
              ...(separator.styles || {}),
            }}
          />
        )}

        {subtitle !== undefined && (
          <div className="banner__container__top__subtitle">
            {typeof subtitle === "string" ? <h2>{subtitle}</h2> : subtitle}
          </div>
        )}
      </div>
      <div className="banner__container__bottom">
        {bottomText && (
          <div className="banner__container__bottom__text">
            {typeof bottomText === "string" ? (
              <h3>{bottomText}</h3>
            ) : (
              bottomText
            )}
          </div>
        )}
      </div>
      {/* <div
        className="banner__container__rings"
        style={{
          backgroundImage: `url("${ringImageUrl}")`,
        }}
      /> */}
    </section>
  );
};

export default Banner;
