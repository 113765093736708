//Third parties
import { useFirestore, useFirestoreCollectionData } from "reactfire";
//Local imports
import { AnyDoc } from "../interfaces/shared";
import { getEnvironment } from "../utils/environment.utils";

const useContent = (): {
  getAll: () => AnyDoc[];
} => {
  /**Firestore reference */
  const firestore = useFirestore();

  const contentCollection = firestore
    .collection("NexwellPower2021")
    .doc(getEnvironment())
    .collection("Content");

  const { data: collectionData } = useFirestoreCollectionData<AnyDoc>(
    contentCollection,
    {
      initialData: null,
      idField: "contentName",
    }
  );

  /**Get all docs from content collection */
  const getAll = (): AnyDoc[] => collectionData;

  return {
    getAll,
  };
};

export default useContent;
