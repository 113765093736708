import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ArrowIcon from "../../components/arrow/arrow.component";
import Banner from "../../components/banner/banner.component";
import OverlayImage from "../../components/image/overlay-image.component";
import { PreloadContext } from "../../context/preload.context";
import { TeamDoc, TeamMember } from "../../interfaces/team";

import "./team.view.scss";
import useViewAnimations, {
  AnimationTarget,
} from "./../../hooks/animations.hook";
import { animeJSBaseParams } from "../../config/animejs.config";
import { getBackgroundWithOverlay } from "../../utils/overlay.utils";
import { AnalyticsContext } from "../../context/analytics.context";
import SEOHead from "../../components/seo-head/seo-head.component";

const formatMemberName = (name: string): JSX.Element => {
  if (name.length < 20) {
    const nameSplit = name.trim().split(" ");
    return (
      <span>
        {nameSplit[0]}
        <br />
        {nameSplit.slice(1)}
      </span>
    );
  }

  return <span>{name}</span>;
};

const Team = () => {
  const targets: AnimationTarget[] = [
    {
      identifier: ".team-section",
      viewOffset: [0, -200],
      animationParams: {
        targets: ".team-section-content__title",
        translateY: [200, 0],
        opacity: [0, 1],
        autoplay: false,
        ...animeJSBaseParams,
      },
    },
    {
      identifier: ".team-section",
      viewOffset: [0, -200],
      animationParams: {
        targets: ".team-section-content__grid .team-member-card",
        translateX: [50, 0],
        translateY: [100, 0],
        opacity: [0, 1],
        delay: function (el, i, l) {
          return i * 100;
        },
        autoplay: false,
        ...animeJSBaseParams,
      },
    },
    {
      identifier: ".join-us-content__pre",
      animationParams: {
        targets: ".join-us-content__pre",
        translateX: [-200, 0],
        opacity: [0, 1],
        autoplay: false,
        ...animeJSBaseParams,
      },
    },
    {
      identifier: ".join-us-content__info",
      animationParams: {
        targets: ".join-us-content__info",
        translateX: [200, 0],
        opacity: [0, 1],
        autoplay: false,
        ...animeJSBaseParams,
      },
    },
  ];

  const [teamData, setTeamData] = useState<TeamDoc | undefined>();
  const { getViewData, isReadyToLoad } = useContext(PreloadContext);

  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext);

  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false);

  //Emit page view event
  useEffect(() => {
    if (teamData && !pageViewSent) {
      //Home data fetched and page view not sent yet
      emitPageView(teamData.seo?.title || "Nexwell Power | Team");

      //Update flag
      setPageViewSent(true);
    }
    // eslint-disable-next-line
  }, [teamData]);

  const teamDataRef = useRef<TeamDoc | undefined>();

  const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    if (teamData) {
      teamDataRef.current = teamData;
      handleLocationChange();
    }
    // eslint-disable-next-line
  }, [teamData]);

  useEffect(() => {
    function resizeHandler() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const viewAnimations = useViewAnimations();
  useEffect(() => {
    // startAuroraAnimations([-window.innerWidth / 2, 0], [600 , 0]);
    viewAnimations.init(targets);
  }, []);

  useEffect(() => {
    if (!selectedMember) {
      const teamSectionAll = document.querySelector("#team-section-all");
      teamSectionAll?.scrollIntoView();
    } else {
      if (window) window.scrollTo(0, 0);
      handleMemberSelection();
    }
  }, [selectedMember]);

  const handleMemberSelection = () => {
    viewAnimations.execute({
      targets: ".team-single-section__cta",
      translateX: [200, 0],
      opacity: [0, 1],
      delay: 200,
      ...animeJSBaseParams,
    });
    viewAnimations.execute({
      targets: ".selected-member-container__image",
      translateX: [-200, 0],
      opacity: [0, 1],
      ...animeJSBaseParams,
    });
    viewAnimations.execute({
      targets: ".selected-member-container__caption",
      translateX: [-200, 0],
      opacity: [0, 1],
      ...animeJSBaseParams,
    });
    viewAnimations.execute({
      targets: ".selected-member-container__bio",
      translateX: [-200, 0],
      opacity: [0, 1],
      ...animeJSBaseParams,
    });
  };

  const history = useHistory();

  const handleLocationChange = () => {
    const { location } = history;

    if (location.pathname !== "/team") return;

    if (!location.search) {
      setSelectedMember(null);
      return;
    } else {
      const name = decodeURI(location.search.split("=")[1]);
      const _selectedMember = teamDataRef.current!.members.find(
        (member) => member.name === name
      );
      if (_selectedMember) {
        setSelectedMember(_selectedMember);
      } else {
        history.push("/team");
      }
    }
  };

  useEffect(() => {
    return history.listen(() => {
      handleLocationChange();
    });
  }, [history]);

  useEffect(() => {
    if (isReadyToLoad) {
      setTeamData(getViewData("Team") as TeamDoc);
    }

    // eslint-disable-next-line
  }, [isReadyToLoad, getViewData("Team")]);

  const { banner, joinUs, members, membersTitle } = teamData! || {};

  return teamData ? (
    <div className="team-view">
      {teamData?.seo ? <SEOHead {...teamData.seo} /> : <></>}
      <Banner
        textureUrl={banner!.textureUrl}
        background={{
          color: banner!.bgColor,
        }}
        separator={{
          color: "#feca2f",
        }}
        ringImageUrl={banner!.ringImageUrl!}
        title={banner!.title}
        specificClassNames={`team ${selectedMember ? "shrink" : ""}`}
        subtitle={
          <>
            <p dangerouslySetInnerHTML={{
              __html: banner!.subtitle as string
            }}>
            </p>
          </>
        }
      />

      {!selectedMember && (
        <>
          <section
            id="team-section-all"
            className="team-section team-view-section"
          >
            <div className="team-section-content">
              {membersTitle ? (
                <h6 className="team-section-content__title">{membersTitle}</h6>
              ) : null}
              <div className="team-section-content__grid">
                {members!.map((member, i) => (
                  <div key={`team-member-${i}`} className="team-member-card">
                    <div className="team-member-card__img-container">
                      <OverlayImage
                        overlayAlpha={member.overlayAlpha || 0}
                        imageClassName="team-member-card__img-container__image"
                        src={member.imageUrl}
                        imageAlt={member.name}
                      />
                    </div>
                    <div className="team-member-card__info">
                      <p className="team-member-card__info__name">
                        {windowWidth < 768 ? (
                          formatMemberName(member.name)
                        ) : (
                          <span>{member.name}</span>
                        )}
                      </p>
                      <p className="team-member-card__info__charge">
                        {member.charge}
                      </p>
                    </div>
                    <Link
                      className="team-member-card__cta"
                      to={`team?name=${member.name}`}
                      id={`team__member-${i}__more`}
                      onMouseEnter={() => {
                        viewAnimations.execute({
                          ...animeJSBaseParams,
                          targets: `#team__member-${i}__more`,
                          scale: ["100%", "110%"],
                          translateX: [0, "5%"],
                        });
                      }}
                      onMouseLeave={() => {
                        viewAnimations.execute({
                          ...animeJSBaseParams,
                          targets: `#team__member-${i}__more`,
                          scale: ["110%", "100%"],
                          translateX: ["5%", 0],
                        });
                      }}
                    >
                      <span>Learn more</span>
                      <ArrowIcon color="#228848" />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}

      {selectedMember && (
        <section className="team-single-section team-view-section">
          <Link
            className="team-single-section__cta"
            to="/team"
            id="team__member__back"
            onMouseEnter={() => {
              viewAnimations.execute({
                ...animeJSBaseParams,
                targets: "#team__member__back",
                scale: ["100%", "110%"],
                translateX: [0, "5%"],
              });
            }}
            onMouseLeave={() => {
              viewAnimations.execute({
                ...animeJSBaseParams,
                targets: "#team__member__back",
                scale: ["110%", "100%"],
                translateX: ["5%", 0],
              });
            }}
          >
            <ArrowIcon color="#003057" />
            <span>Go back to Team</span>
          </Link>

          <div className="selected-member-container">
            <img
              src={selectedMember.imageUrl}
              alt={selectedMember.name}
              className="selected-member-container__image"
            />
            <div className="selected-member-container__info">
              <div className="selected-member-container__info__caption">
                <span className="selected-member-container__info__caption__info">
                  {selectedMember.name} / {selectedMember.charge}
                </span>
              </div>
              <p className="selected-member-container__info__bio">
                {selectedMember.bio}
              </p>
            </div>
          </div>
        </section>
      )}

      {!selectedMember ? (
        <section className="join-us-section">
          <div className="join-us-content">
            <div
              className="join-us-content__pre"
              style={{
                backgroundImage: getBackgroundWithOverlay(joinUs!.imageUrl, 0),
              }}
            ></div>
            <div className="join-us-content__info">
              <div className="join-us-content__info__title">
                <h4 className="join-us-content__info__title__payload">
                  {joinUs!.title}
                </h4>
              </div>
              <div className="join-us-content__info__body">
                <p className="join-us-content__info__body__payload">
                  {joinUs!.bodyText}
                </p>
              </div>
              <div className="join-us-content__info__cta">
                <Link
                  to="/work-with-us"
                  className="join-us-content__info__cta__payload"
                >
                  <span>{joinUs!.ctaText}</span> <ArrowIcon color="#029839" />
                </Link>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </div>
  ) : null;
};

export default Team;
