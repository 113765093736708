//Third parties imports
import { useFirestore } from "reactfire";
//Local imports
import { DeveloperSubmission } from "../interfaces/contact-us";
import { getEnvironment } from "../utils/environment.utils";

export const useSubmissions = () => {
  /**Firestore reference */
  const firestore = useFirestore();

  //Field value reference
  const { FieldValue } = useFirestore;

  /**Generate a new submission */
  const generateSubmission = async (baseData: Partial<DeveloperSubmission>) => {
    try {
      //Add submission
      await firestore
        .collection("NexwellPower2021")
        .doc(getEnvironment())
        .collection("Submissions")
        .add({
          ...baseData,
          reviewed: false, //Not reviewed yet
          createdAt: FieldValue.serverTimestamp(), //Timestamp of creation
        } as DeveloperSubmission);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    generateSubmission,
  };
};
