//React import
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
//Third parties import
import { FirebaseAppProvider } from "reactfire";
//Local imports
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { firebaseClientConfig } from "./config/firebase.config";
import * as ServiceWorker from "./serviceWorkerRegistration";
import { PreloadProvider } from "./context/preload.context";
import SpinnerLayout from "./components/spinner-layout/spinner-layout.component";
import AnalyticsProvider from "./context/analytics.context";
import { HelmetProvider } from "react-helmet-async";
//Styles
import "./index.scss";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<SpinnerLayout />}>
      <FirebaseAppProvider firebaseConfig={firebaseClientConfig}>
        <HelmetProvider context={{}}>
          <AnalyticsProvider>
            <PreloadProvider>
              <App />
            </PreloadProvider>
          </AnalyticsProvider>
        </HelmetProvider>
      </FirebaseAppProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//Register service worker
// ServiceWorker.register();
ServiceWorker.register();
