import React, { useState } from 'react'
import { PreloadContext } from './../../context/preload.context';
import { useContext } from 'react';
import { useEffect } from 'react';
import { FooterDoc } from '../../interfaces/footer';
import { getBackgroundWithOverlay } from '../../utils/overlay.utils';
import { Link } from 'react-router-dom';

import "./footer.component.scss";
import useViewAnimations, { AnimationTarget } from '../../hooks/animations.hook';
import { animeJSBaseParams } from '../../config/animejs.config';

const Footer = () => {

    const targets: AnimationTarget[] = [
        {
            identifier: ".footer-content",
            animationParams: {
              targets: ".footer-content__links",
              opacity: [0, 1],
              translateY: [-200, 0],
              autoplay: false,
              ...animeJSBaseParams,
            },
        },
        {
            identifier: ".footer-content",
            viewOffset: [window.innerWidth < 768 ? -200 : 0],
            animationParams: {
              targets: ".footer-content__contact-us",
              opacity: [0, 1],
              translateY: [200, 0],
              delay: 500,
              autoplay: false,
              ...animeJSBaseParams,
            },
        },
        {
            identifier: ".footer-content",
            viewOffset: [window.innerWidth < 768 ? -200 : 0],
            animationParams: {
              targets: ".footer-content__addresses",
              opacity: [0, 1],
              translateY: [200, 0],
              delay: 800,
              autoplay: false,
              ...animeJSBaseParams,
            },
        }
    ]

    const [ footerData, setFooterData ] = useState<FooterDoc | undefined>();
    const { getViewData, isReadyToLoad } = useContext(PreloadContext);
    const [ listeningToResizing, setListeningToResizing ] = useState(false);
    const [ footerBackgroundImage, setFooterBackgroundImage ] = useState<string | null>(null);

    useEffect(() => {
        if (isReadyToLoad) {
            setFooterData(getViewData("Footer") as FooterDoc);
        }
    }, [isReadyToLoad]);

    const {
        addresses,
        email,
        links,
        backgroundImage,
    } = footerData || {};
    
    const viewAnimations = useViewAnimations();
    useEffect(() => {
        // startAuroraAnimations([-window.innerWidth / 2, 0], [600 , 0]);
        viewAnimations.init(targets);
    }, []);

    useEffect(() => {
        if (!footerData || listeningToResizing) return;

        function handleResizing() {
            let footerBackgroundImageToSet: string;
            if (window.innerWidth >= 768) {
                footerBackgroundImageToSet = footerData!.backgroundImage.desktop;
            } else {
                footerBackgroundImageToSet = footerData!.backgroundImage.mobile;
            }

            setFooterBackgroundImage(footerBackgroundImageToSet);
        }

        window.addEventListener("resize", handleResizing);
        handleResizing();
        
        return () => {
            window.removeEventListener("resize", handleResizing);
        }
    }, [footerData]);

    return (
        footerData ? 
        (
            <footer className="footer-component">
                <div 
                    className="footer-content"
                    style={{
                        backgroundImage: getBackgroundWithOverlay(footerBackgroundImage!, backgroundImage!.overlayAlpha)
                    }}
                >
                    <div className="footer-content__links">
                        {
                            links!.map((link, i) => (
                                <div key={`footer-link-item-${i}`} className="footer-content__links__item">
                                    <Link className="footer-content__links__item__payload" to={link.route}>
                                        {link.name}
                                    </Link>
                                </div>
                            ))
                        }
                    </div>

                    <div className="footer-content__contact-us">
                        <p className="footer-content__contact-us__title">
                            Contact us
                        </p>
                        <a href={`mailto:${email!}`} className="footer-content__contact-us__email">
                            {email!}
                        </a>
                    </div>

                    <div className="footer-content__addresses">
                        {
                            addresses!.map((address, i) => (
                                <div key={`footer-address-item-${i}`} className="footer-content__addresses__item">
                                    <p className="footer-content__addresses__item__payload">
                                        {address.city}
                                    </p>
                                    <p className="footer-content__addresses__item__payload">
                                        {address.address}
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                </div>            
            </footer>
        )
        :
        null
    )
}

export default Footer
