const ArrowIcon = ({
  color,
  style,
  onClick,
}: {
  color?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}) => {
  return (
    <svg
      width="27px"
      height="21px"
      viewBox="0 0 27 21"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M25.5405 10.1822L25.5405 10.0205L25.5405 10.0205C25.538 9.9893 25.5236 9.96033 25.5004 9.93959C25.5004 9.89916 25.4602 9.89916 25.4602 9.85872C25.4602 9.81828 25.4201 9.81828 25.4201 9.77785C25.3979 9.77785 25.3799 9.75974 25.3799 9.73741L16.264 0.234916C15.9381 -0.0783052 15.4253 -0.0783052 15.0994 0.234916C14.9419 0.380427 14.8523 0.585789 14.8523 0.801022C14.8523 1.01625 14.9419 1.22162 15.0994 1.36713L22.7696 9.37349L0.803162 9.37349C0.359588 9.37349 0 9.73556 0 10.1822C0 10.6289 0.359588 10.9909 0.803162 10.9909L22.9303 10.9909L15.0994 18.9973C14.8042 19.3227 14.8221 19.8262 15.1396 20.1295C15.2841 20.2881 15.4881 20.3784 15.7018 20.3784C15.9156 20.3784 16.1195 20.2881 16.264 20.1295L25.3398 10.8292C25.3398 10.7888 25.3398 10.7888 25.3799 10.7483C25.4021 10.7483 25.4201 10.7302 25.4201 10.7079C25.4201 10.6674 25.4602 10.6674 25.4602 10.627L25.5004 10.627L25.5004 10.5461C25.5236 10.5254 25.538 10.4964 25.5405 10.4653L25.5405 10.1822L25.5405 10.1822Z"
        transform="translate(0.35546875 0.3555603)"
        id="Path"
        fill={color || "#0085CA"}
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export default ArrowIcon;
