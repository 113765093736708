//React imports
import { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowIcon from "../../components/arrow/arrow.component";
import Banner from "../../components/banner/banner.component";
import OverlayMedia from "../../components/media/overlay-media.component";
import ProjectCard from "../../components/project-card/project-card.component";
import SEOHead from "../../components/seo-head/seo-head.component";
import { animeJSBaseParams } from "../../config/animejs.config";
import { AnalyticsContext } from "../../context/analytics.context";
//Local imports
import { PreloadContext } from "../../context/preload.context";
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/animations.hook";
import { useProjectsHook } from "../../hooks/projects.hook";
import { Project, ProjectsDoc } from "../../interfaces/projects";
//Styles
import "./projects.view.scss";

const ProjectsView = () => {
  //Contexts
  //Preload context
  const { getViewData } = useContext(PreloadContext);

  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext);

  //Hooks
  //Animation hook
  const { execute, init } = useViewAnimations();

  /**Projects hook */
  const { get: getProjects } = useProjectsHook();

  //State
  //View contents
  const [content, setContent] = useState<ProjectsDoc | undefined>(
    getViewData("Projects") as ProjectsDoc
  );

  //Projects array
  const [projects, setProjects] = useState<Project[]>(getProjects());

  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false);

  //Emit page view event
  useEffect(() => {
    if (content && !pageViewSent) {
      //Home data fetched and page view not sent yet
      emitPageView(content.seo?.title || "Nexwell Power | Projects");

      //Update flag
      setPageViewSent(true);
    }
    // eslint-disable-next-line
  }, [content]);

  //On projects change
  useEffect(() => {
    if (getProjects()) setProjects(getProjects());
  }, [getProjects()]);

  //On contents change
  useEffect(() => {
    //Update content in state
    setContent(getViewData("Projects") as ProjectsDoc);

    // eslint-disable-next-line
  }, [getViewData("Projects")]);

  //On first load
  useEffect(() => {
    //On browser
    if (typeof window !== "undefined")
      window.scrollTo({ top: 0, behavior: "smooth" }); //Scroll to top

    /**Animations targets */
    const targets: AnimationTarget[] = [
      {
        //Join us section image
        identifier: ".projects__join-us__image-container",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".projects__join-us__image-container",
          autoplay: false,
          scale: [0.5, 1],
          opacity: [0, 1],
        },
        midPointOffset: -0.15,
      },
      {
        //Join us section title
        identifier: ".projects__join-us__content__title",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".projects__join-us__content__title",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Join us section separator
        identifier: ".projects__join-us__content__separator",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".projects__join-us__content__separator",
          autoplay: false,
          scale: [0.5, 1],
          opacity: [0, 1],
        },
      },
      {
        //Join us section text
        identifier: ".projects__join-us__content__text",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".projects__join-us__content__text",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
      {
        //Join us section redirection
        identifier: ".projects__join-us__content__link",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".projects__join-us__content__link",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
    ];

    //Animations initialization
    init(targets);
  }, []);

  return (
    <>
      {content?.seo ? <SEOHead {...content.seo} /> : <></>}
      <Banner
        textureUrl={content!.banner.textureUrl}
        specificClassNames={"projects__banner"}
        background={{
          color: content?.banner.backgroundColor ?? "#665BB6",
        }}
        ringImageUrl={content?.banner.ringsUrl ?? ""}
        title={
          <div
            className="projects__banner__title"
            dangerouslySetInnerHTML={{
              __html: content?.banner.title ?? "",
            }}
          />
        }
        separator={{
          color: content?.banner.separatorColor,
        }}
        subtitle={
          <div
            className="projects__banner__subtitle"
            dangerouslySetInnerHTML={{
              __html: content?.banner.subtitle ?? "",
            }}
          />
        }
      />
      <section className="projects__list">
        {projects.map((project) => (
          <div
            key={`projects-project-${project.id}`}
            className="projects__list__item"
          >
            <ProjectCard
              project={project}
              separatorColor={content?.projects.separatorsColor ?? "#FECA2F"}
              redirectionColor={
                content?.projects.redirectionsColor ?? "#029839"
              }
            />
          </div>
        ))}
      </section>
      <section className="projects__join-us">
        <OverlayMedia
          media={{
            ...(content?.joinUs.media || {
              type: "image",
              url: "",
            }),
          }}
          containerClassName="projects__join-us__image-container"
          imageAlt="Sunflowers field"
          mediaClassName="projects__join-us__image"
        />
        {/* <div className="projects__join-us__content">
          <div
            className="projects__join-us__content__title"
            dangerouslySetInnerHTML={{
              __html: content?.joinUs.title ?? "",
            }}
          />
          <hr
            className="projects__join-us__content__separator"
            style={{
              backgroundColor: content?.joinUs.separatorColor ?? "#FECA2F",
            }}
          />
          <div
            className="projects__join-us__content__text"
            dangerouslySetInnerHTML={{
              __html: content?.joinUs.text ?? "",
            }}
          />
          <Link
            style={{
              color: content?.joinUs.redirection.color,
            }}
            className="projects__join-us__content__link redirection-link"
            to={content?.joinUs.redirection.route ?? ""}
            id="projects__join-us__link"
            onMouseEnter={() => {
              execute({
                ...animeJSBaseParams,
                targets: "#projects__join-us__link",
                scale: [1, 1.1],
                translateX: [0, "5%"],
                opacity: [1, 0.7],
              })
            }}
            onMouseLeave={() => {
              execute({
                ...animeJSBaseParams,
                targets: "#projects__join-us__link",
                scale: [1.1, 1],
                translateX: ["5%", 0],
                opacity: [0.7, 1],
              })
            }}
          >
            {content?.joinUs.redirection.name}
            <ArrowIcon color={content?.joinUs.redirection.color} />
          </Link>
        </div> */}
      </section>
    </>
  )
};

export default ProjectsView;
