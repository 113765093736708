//Styles
import { useCallback, useEffect, useRef } from "react";
import { MediaObject } from "../../interfaces/shared";
import "./overlay-media.component.scss";

//Definitions
/**Media with overlay props */
interface OverlayMediaProps {
  media: MediaObject;

  /**Media class name */
  mediaClassName?: string;

  /**Container class name */
  containerClassName?: string;

  /**Media alt */
  imageAlt?: string;

  /**Video played/paused callback */
  onMediaClick?: () => void;
}

/**Media with overlay component */
const OverlayMedia = ({
  containerClassName,
  mediaClassName,
  imageAlt,
  media: { overlayAlpha, type, url },
  onMediaClick
}: OverlayMediaProps) => {
  //Refs
  /**Video ref */
  const videoRef = useRef<HTMLVideoElement | null>(null)

  /**Iframe ref to infer click */
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const iframeCallbackRef = useCallback(
    (node: null | HTMLIFrameElement): void => { iframeRef.current = node },
    [],
  )

  //On video ref current change
  useEffect(() => {
    if (videoRef.current) {
      //Set new playback rate on ref initialized
      videoRef.current.playbackRate = 0.6
    }
  }, [videoRef.current])

  // Setup iframe click infer
  useEffect(() => {
    // Setup blur event listener
    const onBlur = () => {
      if (document.activeElement &&
        document.activeElement.nodeName.toLocaleLowerCase() === 'iframe' &&
        iframeRef.current &&
        iframeRef.current === document.activeElement &&
        onMediaClick
      ) {
        // Infer a click event
        onMediaClick()
      }
    }
    window.addEventListener('blur', onBlur)

    // Cleanup blur event
    return () => {
      window.removeEventListener('blur', onBlur)
    }
  }, []);

  /**Iframe params */
  const iframeParams = {
    youtube: "?controls=0",
    vimeo: "?controls=0",
  }

  /**Media type to children */
  const mediaTypeToChildren = {
    image: <img src={url} alt={imageAlt} className={mediaClassName} onClick={onMediaClick} />,
    video: (
      <video
        muted
        loop
        autoPlay
        playsInline
        ref={videoRef}
        src={url}
        className={mediaClassName}
        onPlay={onMediaClick}
        onPause={onMediaClick}
      ></video>
    ),
    iframe: (
      <iframe
        ref={iframeCallbackRef}
        src={
          url +
          (url?.includes("player.vimeo")
            ? iframeParams.vimeo
            : iframeParams.youtube)
        }
        className={mediaClassName}
      ></iframe>
    ),
  }

  return (
    <div
      className={`media__container${
        containerClassName ? ` ${containerClassName}` : ""
      }`}
    >
      {type !== 'iframe' && <div
        className="media__container__overlay"
        style={{
          opacity:
            overlayAlpha && overlayAlpha > 0
              ? overlayAlpha < 1
                ? overlayAlpha
                : 1
              : 0,
        }}
      ></div>}
      {mediaTypeToChildren[type]}
    </div>
  )
}

export default OverlayMedia;
