//Third parties imports
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { OfferPosition } from "../interfaces/work-with-us";
import { getEnvironment } from "../utils/environment.utils";

/**Work with us hook */
export const usePositions = (limit?: number) => {
  /**Firestore reference */
  const firestore = useFirestore();

  /**Positions query */
  const positionsQuery = firestore
    .collection("NexwellPower2021") //From
    .doc(getEnvironment()) //Current environment
    .collection("Positions") //Get positions
    .where("deleted", "==", false) //That are not deleted
    .where("active", "==", true) //That are active
    .orderBy("deadline", "asc"); //Ordered by deadline

  /**Positions data */
  const { data: positions } = useFirestoreCollectionData<OfferPosition>(
    limit && limit > 0 ? positionsQuery.limit(limit) : positionsQuery,
    {
      idField: "id",
      initialData: [],
    }
  );

  /**Get positions from query */
  const get = (): OfferPosition[] => positions;

  return { get };
};
