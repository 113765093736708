import { useEffect, useRef } from "react"
import { PDFPageProxy } from "pdfjs-dist"
import { RenderParameters } from "pdfjs-dist/types/src/display/api"
import "./pdf-viewer-page.component.scss"

const PdfViewerPage = ({
  page,
  pageId,
  zoom,
}: {
  page: PDFPageProxy
  pageId: string
  zoom: number
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (canvasRef.current) {
      const viewport = page.getViewport({ scale: zoom })

      // Prepare canvas using PDF page dimensions and device pixel ratio
      const devicePixelRatio = window.devicePixelRatio || 1
      const canvas = canvasRef.current

      canvas.height = devicePixelRatio * viewport.height
      canvas.width = devicePixelRatio * viewport.width
      canvas.style.height = viewport.height + "px"
      canvas.style.width = viewport.width + "px"

      // Render PDF page into canvas context.
      const canvasContext = canvas.getContext("2d")
      const renderContext: RenderParameters = {
        canvasContext: canvasContext!,
        viewport,
        transform: [devicePixelRatio, 0, 0, devicePixelRatio, 0, 0],
      }

      page
        .render(renderContext)
        .promise.then(() => {})
        .catch((err) => {
          console.log("Page", page.pageNumber, "couldn't render", err)
        })
    }
  }, [page, zoom, canvasRef])

  return (
    <div id={pageId} className="pdf-viewer__page__container">
      <canvas ref={canvasRef}></canvas>
    </div>
  )
}

export default PdfViewerPage
