//React imports
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { animeJSBaseParams } from "../../config/animejs.config";
//Local imports
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/animations.hook";
import ArrowIcon from "../arrow/arrow.component";
//Styles
import "./submission-form.component.scss";

//Declarations
/**Submission form fields */
export interface SubmissionFormFields {
  /**Name of the submission */
  name: string;

  /**Phone of the submission */
  phone: string;

  /**Email of the submission */
  email: string;

  /**Optional message */
  message: string;
}

/**Submission form props */
interface SubmissionFormProps {
  /**On submit callback */
  onSubmit: (data: SubmissionFormFields) => Promise<void>;

  /**Submission color */
  submitColor: string;
}

/**Submission form component */
const SubmissionForm = ({ onSubmit, submitColor }: SubmissionFormProps) => {
  //Hooks
  //Animations hook
  const { init, execute } = useViewAnimations();

  //State
  //Form data
  const [formData, setFormData] = useState<SubmissionFormFields>({
    email: "",
    message: "",
    name: "",
    phone: "",
  });

  //Is loading flag
  const [isLoading, setIsLoading] = useState(false);

  //Methods
  /**
   * Handle an input change
   * @param {ChangeEvent<HTMLInputElement>} e Input change
   */
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  /**Is form valid */
  const isFormValid = (): boolean =>
    formData.email !== "" && formData.name !== "" && formData.phone !== "";

  /**
   * Handle form submit
   */
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    //Prevent default of event
    e.preventDefault();

    if (!isLoading && isFormValid()) {
      //If not loading and form is valid, send submit
      //Set as loading
      setIsLoading(true);

      //Send submit
      await onSubmit(formData);

      //Set as not loading
      setIsLoading(false);

      //Reset form
      setFormData({
        email: "",
        message: "",
        name: "",
        phone: "",
      });
    }
  };

  //On initial render
  useEffect(() => {
    /**Targets for animations */
    const targets: AnimationTarget[] = [
      {
        //Form input rows
        identifier: ".submission-form__input-row",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".submission-form__input-row",
          autoplay: false,
          scale: [0.5, 1],
          opacity: [0, 1],
        },
      },
      {
        //Form input submit button
        identifier: "#submission-form__submit",
        animationParams: {
          ...animeJSBaseParams,
          targets: "#submission-form__submit",
          autoplay: false,
          translateX: ["-100vw", 0],
        },
      },
    ];

    //Initialize animations
    init(targets);
  }, []);

  return (
    <form className="submission-form" onSubmit={handleSubmit}>
      <div className="submission-form__input-row">
        <div className="submission-form__input-row__input-container">
          <input
            id="submission-name"
            name="name"
            className="submission-form__input-row__input-container__input"
            placeholder="Name"
            onChange={handleInputChange}
          />
        </div>
        <div className="submission-form__input-row__input-container">
          <input
            id="submission-phone"
            name="phone"
            className="submission-form__input-row__input-container__input"
            placeholder="Phone"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="submission-form__input-row">
        <div className="submission-form__input-row__input-container">
          <input
            id="submission-email"
            name="email"
            className="submission-form__input-row__input-container__input"
            placeholder="Email"
            onChange={handleInputChange}
          />
        </div>
        <div className="submission-form__input-row__input-container">
          <input
            id="submission-message"
            name="message"
            className="submission-form__input-row__input-container__input"
            placeholder="Message"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <button
        style={{
          color: submitColor,
        }}
        className="submission-form__submit redirection-link"
        type="submit"
        disabled={!isFormValid() || isLoading}
        id="submission-form__submit"
        onMouseEnter={() => {
          execute({
            ...animeJSBaseParams,
            targets: "#submission-form__submit",
            translateX: [0, "5%"],
            scale: [1, 1.1],
          });
        }}
        onMouseLeave={() => {
          execute({
            ...animeJSBaseParams,
            targets: "#submission-form__submit",
            translateX: ["5%", 0],
            scale: [1.1, 1],
          });
        }}
      >
        Send
        <ArrowIcon color={submitColor} />
      </button>
    </form>
  );
};

export default SubmissionForm;
