//React imports
import { useEffect, useState } from "react";
//Third parties imports
import { animeJSBaseParams } from "../../config/animejs.config";
//Local imports
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/animations.hook";
import { Project } from "../../interfaces/projects";
import { MediaObject } from "../../interfaces/shared";
import ArrowIcon from "../arrow/arrow.component";
import ImageCarousel from "../media-carousel/media-carousel.component";
import OverlayMedia from "../media/overlay-media.component";
//Styles
import "./project-card.component.scss";

//Definitions
interface ProjectCardProps {
  /**Project data */
  project: Project;

  /**Separator color */
  separatorColor: string;

  /**Redirection color */
  redirectionColor: string;
}

const ProjectCard = ({
  project,
  separatorColor,
  redirectionColor,
}: ProjectCardProps) => {
  const images: MediaObject[] = project.images ? project.images.map(({ type, ...media }) => ({
    ...media,
    type,
    onIframe: type === 'video'
  })) : [
    { ...project.homeImage, type: "image" },
    { ...project.image, type: "image" },
  ];

  //Hooks
  //Animations hook
  const { init, execute } = useViewAnimations();

  //On first load
  useEffect(() => {
    /**Targets for animations */
    const targets: AnimationTarget[] = [
      {
        identifier: `#project-card-${project.id}`,
        animationParams: {
          ...animeJSBaseParams,
          targets: `#project-card-${project.id}`,
          autoplay: false,
          scale: [0.5, 1],
          opacity: [0, 1],
        },
      },
    ];

    //Initialize animations
    init(targets);
  }, []);

  return (
    <div id={`project-card-${project.id}`} className="project-card">
      <ImageCarousel
        mediaItems={images}
        containerClassName="project-card__image-container"
        mediaClassName="project-card__image"
      />
      <div className="project-card__content">
        <h4 className="project-card__content__title">{project.name}</h4>
        <div className="project-card__content__separator-container">
          <hr
            style={{
              backgroundColor: separatorColor,
            }}
            className="project-card__content__separator-container__separator"
          />
        </div>
        <div className="project-card__content__texts">
          <p className="project-card__content__texts__text">
            <span>Status:</span> {project.status}
          </p>
          <p className="project-card__content__texts__text">
            <span>Location:</span> {project.location}
          </p>
          <p className="project-card__content__texts__text">
            <span>Installed Capacity:</span> {project.capacity}
          </p>
          <p className="project-card__content__texts__text">
            <span>First year energy production:</span>{" "}
            {project.firstYearProduction}
          </p>
          <p className="project-card__content__texts__text">
            <span>First year avoided CO2:</span> {project.firstYearAvoidance}
          </p>
        </div>
        <a
          style={{
            color: redirectionColor,
          }}
          target="_blank"
          rel="noreferrer"
          href={project.redirection.route}
          className="project-card__content__link redirection-link"
          id={`project-${project.id}-link`}
          onMouseEnter={() => {
            execute({
              ...animeJSBaseParams,
              targets: `#project-${project.id}-link`,
              translateX: [0, "5%"],
              scale: [1, 1.1],
              opacity: [1, 0.7],
            });
          }}
          onMouseLeave={() => {
            execute({
              ...animeJSBaseParams,
              targets: `#project-${project.id}-link`,
              translateX: ["5%", 0],
              scale: [1.1, 1],
              opacity: [0.7, 1],
            });
          }}
        >
          {project.redirection.name}
          <ArrowIcon color={redirectionColor} />
        </a>
      </div>
    </div>
  );
};

export default ProjectCard;
